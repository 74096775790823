import React, {Fragment, ReactElement} from "react";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {ITutorialController} from "views/components/tutorial/tutorial.controller";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Modal, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {PureButton} from "views/components/pure_button/pure_button.component";
import {Exist} from "views/components/exist/exist.component";
import chevron from "assets/img/icons/chevronLeft.svg";
import {A11y} from "swiper/modules";
import {TutorialStep} from "views/components/tutorial/tutorial_step/tutorial_step.component";
import {ModalContent} from "views/components/modals/modal_content/modal_content.components";
import {Swiper, SwiperSlide} from "swiper/react";
import {ReactComponent as Logo} from "assets/img/tutorial/step_0.svg";
import step_1 from "assets/img/tutorial/step_1.svg";
import step_2 from "assets/img/tutorial/step_2.svg";
import step_3 from "assets/img/tutorial/step_3.svg";
import step_4 from "assets/img/tutorial/step_4.svg";
import step_5 from "assets/img/tutorial/step_5.svg";
import {ReactComponent as LastIcon} from "assets/img/tutorial/step_6.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
	SlideDot,
	SlideNextButton,
	SlidePrevButton,
	TutorialDesc,
	TutorialTitle,
} from "views/components/tutorial/common";

const StyledModalContent = styled(ModalContent)`
	padding: 52px 20px 40px 20px;

	height: 352px;
	@media screen and (min-width: 768px) {
		height: 328px;
	}
`;

const Pagination = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	height: 24px;
`;

const Wrapper = styled.div`
	max-width: 100%;
	width: 100%;
	overflow: hidden;

	.swiper-horizontal {
		--swiper-theme-color: #b4b2cd;
		--swiper-pagination-bullet-inactive-color: #4e6194;
	}
	.swiper-slide {
		display: inline-block;
		width: auto;
	}

	.swiper-button-prev {
		background: url(${chevron}) no-repeat center;
	}
	.swiper-button-next {
		background: url(${chevron}) no-repeat center;
		rotate: 180deg;
	}

	.swiper-wrapper {
		height: 230px;
		@media screen and (min-width: 768px) {
			height: 210px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		height: 24px;
		width: 24px;
		margin: 0;
		position: static;
		text-rendering: auto;
		--swiper-navigation-size: 24px;
		--swiper-navigation-sides-offset: 0;
		--swiper-navigation-color: #fff;
		background-size: cover;

		&:after {
			content: "";
		}
	}

	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: #c7c7cc;
		transition:
			background-color 0.4s,
			width 0.4s;
		transition-delay: 0.4s, 0s;
		opacity: 1;
	}

	.swiper-pagination-bullet-active {
		height: 12px;
		width: 12px;
		background: #7600d2;
		transition:
			background-color 0.4s ease 0s,
			width 0.4s ease;
		border-radius: 50%;
	}
`;
const CloseButton = styled(PureButton)`
	position: absolute;
	right: 16px;
	top: 20px;
	color: #000;
	z-index: 2;

	svg {
		font-size: 28px;
	}
`;

interface IStep {
	target: string;
	placement: string;
	content: ReactElement;
}

export const steps: IStep[] = [
	{
		target: "body",
		content: <TutorialStep img={step_1} index={1} />,
		placement: "center",
	},
	{
		target: "body",
		content: <TutorialStep img={step_2} index={2} />,
		placement: "center",
	},
	{
		target: "body",
		content: <TutorialStep img={step_3} index={3} />,
		placement: "center",
	},
	{
		target: "body",
		content: <TutorialStep img={step_4} index={4} />,
		placement: "center",
	},
	{
		target: "body",
		content: <TutorialStep img={step_5} index={5} />,
		placement: "center",
	},
];

export const Tutorial: React.FC = observer(() => {
	const {isOpen, activeStep, i18n, next, restart, close, setActiveStep} =
		useViewController<ITutorialController>(Bindings.TutorialController);
	const isFirst = activeStep === 0;
	const isLast = activeStep === steps.length + 1;
	return (
		<Modal open={isOpen}>
			<StyledModalContent>
				<CloseButton onClick={close}>
					<CloseIcon />
				</CloseButton>
				<Exist when={isFirst}>
					<Fragment>
						<Logo />
						<TutorialTitle>
							{i18n.t("tutorial.welcome_header.copy", "Welcome to sooka GOT GAME")}
						</TutorialTitle>
						<Stack
							alignItems="center"
							direction={{xs: "column", sm: "row"}}
							justifyContent="center"
							spacing={1}
							mt={"auto"}
							sx={{width: "100%"}}>
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								onClick={next}
								sx={{flex: 1}}>
								{i18n.t("tutorial.view_tutorial.button", "Quick Guide")}
							</Button>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={close}
								sx={{flex: 1}}>
								{i18n.t("tutorial.play_now.button", "Play Now")}
							</Button>
						</Stack>
					</Fragment>
				</Exist>
				<Exist when={activeStep > 0 && activeStep < steps.length + 1}>
					<Wrapper>
						<Swiper
							// install Swiper modules
							modules={[A11y]}
							slidesPerView={1}
							centeredSlides
							onSlideChange={setActiveStep}>
							{steps.map((step, index) => (
								<SwiperSlide key={index}>{step.content}</SwiperSlide>
							))}
							<SwiperSlide />

							<Pagination>
								<SlidePrevButton />
								{[...steps, 1].map((_, index) => (
									<SlideDot activeStep={activeStep} key={index} index={index} />
								))}
								<SlideNextButton />
							</Pagination>
						</Swiper>
					</Wrapper>
				</Exist>
				<Exist when={isLast}>
					<Fragment>
						<LastIcon />
						<TutorialTitle>
							{i18n.t("tutorial.exit_header.copy", "You’re ready!")}
						</TutorialTitle>
						<TutorialDesc>
							{i18n.t("tutorial.exit_body.copy", "You’re ready!")}
						</TutorialDesc>

						<Stack
							alignItems="center"
							direction={{xs: "column", sm: "row"}}
							justifyContent="center"
							spacing={1}
							mt={"auto"}
							sx={{width: "100%"}}>
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								onClick={restart}
								sx={{flex: 1}}>
								{i18n.t("tutorial.reply_tutorial.button", "Restart Quick Guide")}
							</Button>
							<Button
								fullWidth
								variant="contained"
								color="primary"
								onClick={close}
								sx={{flex: 1}}>
								{i18n.t("tutorial.get_started.button", "Get Started")}
							</Button>
						</Stack>
					</Fragment>
				</Exist>
			</StyledModalContent>
		</Modal>
	);
});
