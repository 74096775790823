import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {get} from "lodash";
import {Bindings} from "data/constants/bindings";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {ViewController} from "data/types/structure";
import type {IGame} from "data/stores/rounds/rounds.store";

export interface IPredictionsListController extends ViewController {
	get games(): IGame[];
	get isLoading(): boolean;
}

@injectable()
export class PredictionsListController implements IPredictionsListController {
	constructor(@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore) {
		makeAutoObservable(this);
	}

	get games() {
		const selectedGameWeek = this._predictionsStore.selectedGameWeek;
		return get(selectedGameWeek, "games", []) as IGame[];
	}

	get isLoading() {
		return this._predictionsStore.isLoadingFetchPredictions;
	}
}
