import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {RoundStatus} from "data/enums";
import {first, last} from "lodash";

export interface IPopularPrediction {
	homeScore: number;
	awayScore: number;
	percentage: number;
}

export interface IGame {
	id: number;
	date: Date;
	status: string;
	homeId: number;
	homeScore: number;
	awayId: number;
	awayScore: number;
	popularPredictions: null | IPopularPrediction[];
}
export interface IRound {
	id: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	games: IGame[];
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get list(): IRound[];

	get scheduleRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get scoreRound(): IRound | null;

	get listForLadder(): IRound[];

	getRoundById(roundId: number): IRound | undefined;

	getGameById(id: number): IGame | undefined;

	fetchRounds(): Promise<void>;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: IRound[] = [];

	get list() {
		return this._list;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Completed);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get currentRound() {
		return this.activeRound || first(this.scheduleRounds) || last(this.list);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds) || null;
	}

	get listForLadder() {
		return this.list.filter((round) => {
			const firstGameStatus = first(round.games)?.status;
			return firstGameStatus && firstGameStatus !== RoundStatus.Scheduled;
		});
	}

	getRoundById(roundId: number) {
		return this.list.find(({id}) => id === roundId);
	}

	getGameById(id: number | null) {
		return this.list
			.map(({games}) => games)
			.flat()
			.find((game) => game.id === id);
	}

	private get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	@action
	async fetchRounds() {
		const {data} = await this._jsonProvider.rounds();

		runInAction(() => {
			this._list = data;
		});
	}
}
