import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {useLocation} from "react-router-dom";
import type {INavigationStore} from "data/stores/navigation/navigation.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {ShareType, SocialNetwork} from "data/enums";
import {share} from "data/utils";

interface IProps {
	location: ReturnType<typeof useLocation>;
}

export interface IHeaderController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;
	get isAuth(): boolean;
	get isPrizeEnd(): boolean;
	get isMobileMenuOpen(): boolean;

	toggleMenu: () => void;
	showTutorial: () => void;
	shareViaFB: () => void;
	shareViaTW: () => void;
	shareViaMobile: () => void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _location?: ReturnType<typeof useLocation>;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.NavigationStore) private _navigationStore: INavigationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	get isAuth() {
		return this._userStore.isAuthorized;
	}
	get isPrizeEnd() {
		return Boolean(this._location?.pathname.includes("prize"));
	}
	get isMobileMenuOpen() {
		return this._navigationStore.isOpen;
	}

	toggleMenu = () => {
		this._navigationStore.toggleMenu();
	};
	onChange(props: IProps) {
		this._location = props.location;
	}

	showTutorial = () => {
		this._tutorialStore.setIsOpen(true);
	};

	private share(socialNetwork: SocialNetwork) {
		share({
			socialNetwork,
			message: this.i18n.t(
				"header.share.copy",
				"Join my sooka GOT GAME league by using the code: {{CODE}}.  Make your predictions for each game and compete for amazing prizes!"
			),
			type: ShareType.General,
		});
	}

	shareViaFB = () => this.share(SocialNetwork.Facebook);

	shareViaTW = () => this.share(SocialNetwork.Twitter);

	shareViaMobile = () => this.share(SocialNetwork.Mobile);

	init(props: IProps) {
		this._location = props.location;
	}
}
