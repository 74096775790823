export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	LiveUpdatesStore: Symbol("LiveUpdatesStore"),
	HeaderController: Symbol("HeaderController"),
	StatesStore: Symbol("StatesStore"),
	ChangePasswordController: Symbol("ChangePasswordController"),
	DeleteAccountController: Symbol("DeleteAccountController"),
	LogoutController: Symbol("LogoutController"),
	LeagueManageController: Symbol("LeagueManageController"),
	LeagueCardController: Symbol("LeagueCardController"),
	LeaguesSearchController: Symbol("LeaguesSearchController"),
	JoinLeaguesCardController: Symbol("JoinLeaguesCardController"),
	ModalLeagueJoinController: Symbol("ModalLeagueJoinController"),
	LeagueLadderController: Symbol("LeagueLadderController"),
	PredictorController: Symbol("PredictorController"),
	PredictionsStore: Symbol("PredictionsStore"),
	PredictionsApiProvider: Symbol("PredictionsApiProvider"),
	PredictionsListController: Symbol("PredictionsListController"),
	PredictionsListItemController: Symbol("PredictionsListItemController"),
	BottomActionsController: Symbol("BottomActionsController"),
	PredictionSavedModalController: Symbol("PredictionSavedModalController"),
	ClearPredictionModalController: Symbol("ClearPredictionModalController"),
	MakePredictionsModalController: Symbol("MakePredictionsModalController"),
	LiveScoreController: Symbol("LiveScoreController"),
	PredictorSummaryBarController: Symbol("PredictorSummaryBarController"),
	MobileMenuController: Symbol("MobileMenuController"),
	NavigationStore: Symbol("NavigationStore"),
	RankingsController: Symbol("RankingsController"),
	RankingsStore: Symbol("RankingsStore"),
	TutorialStore: Symbol("TutorialStore"),
	TutorialController: Symbol("TutorialController"),
	TutorialStepController: Symbol("TutorialStepController"),
	GeoBlockStore: Symbol("GeoBlockStore"),
	GeoBlockController: Symbol("GeoBlockController"),
	GeoBlockProvider: Symbol("GeoBlockProvider"),
	MonthsStore: Symbol("MonthsStore"),
};
