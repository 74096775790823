import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType, RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IDeleteAccountController extends ViewController {
	get error(): Record<string, string> | null;
	get user(): IUser;
	get i18n(): ILocalizationStore;
	get isRequestStatePending(): boolean;

	handleClearErrorOnChange: () => void;
	deleteAccountModal: () => void;
}

@injectable()
export class DeleteAccountController implements IDeleteAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isRequestStatePending() {
		return this._requestState === RequestState.PENDING;
	}

	get user() {
		return this._userStore.user!;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	@action deleteAccountModal = () => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t("delete_account.modal.body"),
			title: this.i18n.t("delete_account.modal.header"),
			// confirmCopy: this.i18n.t("delete_account.modal.yes_button"),
			// confirmColor: "error",
			callback: () => {
				runInAction(() => {
					this._requestState = RequestState.PENDING;
				});

				this._modalsStore.hideModal();

				this._userStore.deactivate().catch(this.onError);
			},
		});
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: this._errorMsg,
		});
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
	};
}
