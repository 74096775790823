import React from "react";
import styled from "@emotion/styled";
import {ReactComponent as Logo} from "assets/img/logo.svg";
import {ReactComponent as GsLogo} from "assets/img/gsLogo.svg";
import {ReactComponent as YoutubeIcon} from "assets/img/youtubeIcon.svg";
import {ReactComponent as FbIcon} from "assets/img/fbIcon.svg";
import {ReactComponent as InstaIcon} from "assets/img/instaIcon.svg";
import {ReactComponent as XIcon} from "assets/img/xIcon.svg";
import play from "assets/img/play.png";
import store from "assets/img/store.png";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {Link, Stack} from "@mui/material";
import {ExternalLink} from "views/components/links/links.component";
import {NavLink} from "react-router-dom";

const FooterWrap = styled.div`
	height: 100%;
	width: 100%;
	background: #220e35;
	color: #fff;
	min-height: 113px;
	margin-top: auto;

	border-top: 4px solid;
	border-image-source: linear-gradient(
		to right,
		#00a268 25%,
		#4396af 25%,
		#4396af 50%,
		#b20023 50%,
		#b20023 75%,
		#b27d00 75%
	);
	border-image-slice: 1;

	a {
		color: #fff;
	}
`;

const FooterInner = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	.desc {
		color: #c7c7cc;
		text-decoration: none;
		font-size: 12px;
		font-weight: 700;
		line-height: 135%;
		@media (min-width: 769px) {
			font-weight: 400;
		}
	}

	.links {
		a {
			font-family: var(--fontFamilyBase);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 140%; /* 19.6px */
			text-decoration-line: underline;
			text-transform: capitalize;
			@media (min-width: 769px) {
				font-size: 16px;
				line-height: 150%;
			}
		}
	}

	@media (min-width: 769px) {
		display: block;
	}
`;

const ExternalLinkPlay = styled(ExternalLink)`
	height: 25px;
	width: 84px;
	background: url(${play}) center no-repeat;
	background-size: cover;
	display: block;
`;
const ExternalLinkStore = styled(ExternalLink)`
	height: 25px;
	width: 75px;
	background: url(${store}) center no-repeat;
	background-size: cover;
	display: block;
`;

const Desc = styled.div`
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 135%;
	color: #c7c7cc;
	text-align: center;
	@media (min-width: 769px) {
		font-size: 16px;
		text-align: left;
	}
`;

const SubDesc = styled.p`
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

export const Footer: React.FC = () => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<FooterWrap>
			<FooterInner className="gap-4">
				<div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-6">
					<div className="flex items-center gap-4 md:gap-6">
						<Link href="/">
							<Logo />
						</Link>
						<Link
							className="hidden md:flex items-center gap-4 md:gap-2 desc"
							href={"https://geniussports.com/"}>
							{i18n.t("footer.logo.powered_by")}
							<GsLogo />
						</Link>
					</div>
					<Stack
						className="links"
						direction="row"
						alignItems="center"
						spacing={{xs: 1, sm: 2}}
						divider={<p>|</p>}>
						<NavLink className="text-center" to="help/t&cs">
							{i18n.t("footer.terms.text", "terms")}
						</NavLink>
						<NavLink className="text-center" to="help/contact-us">
							{i18n.t("footer.contact_us.text", "contact_us")}
						</NavLink>
						<ExternalLink
							className="text-center"
							href="https://support.sooka.my/hc/en-us/articles/360004002396-Privacy-Policy?_gl=1*1ipwirr*_ga*NjEyNDQwMTU2LjE3MDk5MDYyMDg.*_ga_KV9K30PTL0*MTcxMTAzOTIzOC4xNS4wLjE3MTEwMzkyNDMuNTUuMC4yNjc1ODk1OTc.">
							{i18n.t("footer.privacy_policy.text", "privacy_policy")}
						</ExternalLink>
					</Stack>
				</div>
				<div className="flex flex-col-reverse md:flex-row items-center md:items-end justify-between gap-2 md:gap-4">
					<Desc className="flex items-center gap-4">
						{i18n.t(
							"footer.copy.text",
							"sooka © 2024 MEASAT Broadcast Network Systems Sdn Bhd (240064-A). All Rights Reserved."
						)}
					</Desc>
					<Link
						className="flex md:hidden items-center gap-4 desc"
						href={"https://geniussports.com/"}>
						{i18n.t("footer.logo.powered_by")}
						<GsLogo />
					</Link>
					<div className="flex items-center justify-between gap-4 md:gap-11">
						<Stack spacing={1}>
							<SubDesc>{i18n.t("footer.connect_with_us.text", "Connect")}</SubDesc>
							<Stack direction="row" spacing={{xs: 1, sm: 2}}>
								<ExternalLink href="https://www.facebook.com/sookamalaysia/">
									<FbIcon />
								</ExternalLink>
								<ExternalLink href="https://www.instagram.com/sookamalaysia/">
									<InstaIcon />
								</ExternalLink>
								<ExternalLink href="https://twitter.com/sookamalaysia/">
									<XIcon />
								</ExternalLink>
								<ExternalLink href="https://www.youtube.com/c/sookamalaysia">
									<YoutubeIcon />
								</ExternalLink>
							</Stack>
						</Stack>
						<Stack spacing={1}>
							<SubDesc className="text-right">
								{i18n.t("footer.download_app.text", "Download")}
							</SubDesc>
							<Stack direction="row" spacing={1}>
								<ExternalLinkPlay href="https://play.google.com/store/apps/details?id=com.astro.sott&pli=1" />
								<ExternalLinkStore href="https://apps.apple.com/my/app/sooka/id1472711509" />
							</Stack>
						</Stack>
					</div>
				</div>
			</FooterInner>
		</FooterWrap>
	);
};
