import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

export interface IBottomActionsController extends ViewController {
	i18n: ILocalizationStore;
	autoFillHandler: () => void;
	clearHandler: () => void;
	saveHandler: () => void;

	get isShowAutoFillButton(): boolean;

	get isShowClearButton(): boolean;

	get isDisabledSaveButton(): boolean;

	get isDisabledAutoFillButton(): boolean;

	get isDisabledClearButton(): boolean;
}

@injectable()
export class BottomActionsController implements IBottomActionsController {
	@observable _userFLowDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _gameWeeksStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isShowAutoFillButton() {
		return this._predictionsStore.isShowAutoFillButton;
	}

	get isShowClearButton() {
		return this._predictionsStore.isShowClearButton;
	}

	get isDisabledSaveButton() {
		return this._predictionsStore.isDisabledSaveButton;
	}

	get isDisabledClearButton() {
		return this._predictionsStore.isDisabledAnyActions;
	}

	get isDisabledAutoFillButton() {
		return this._predictionsStore.isDisabledAnyActions;
	}

	autoFillHandler = () => {
		const selectedGameWeek = this._predictionsStore.selectedGameWeek;

		if (!selectedGameWeek) {
			return;
		}

		this._predictionsStore.autoFill(selectedGameWeek.id).catch((err) => {
			runInAction(() => {
				const message = extractErrorMessage(err as AxiosError<IApiResponse>);
				this._modalsStore.showModal(ModalType.ERROR, {message});
			});
		});
	};

	clearHandler = () => {
		this._modalsStore.showModal(ModalType.CLEAR_PREDICTIONS);
	};

	@action
	saveHandler = () => {
		const selectedGameWeek = this._predictionsStore.selectedGameWeek;

		if (!this._predictionsStore.predictionsForSave.length || !selectedGameWeek) {
			return;
		}

		this._predictionsStore
			.save(selectedGameWeek.id)
			.then(() => {
				runInAction(() => {
					this._modalsStore.showModal(ModalType.PREDICTIONS_SAVED);
				});
			})
			.catch((err) => {
				runInAction(() => {
					const message = extractErrorMessage(err as AxiosError<IApiResponse>);
					this._modalsStore.showModal(ModalType.ERROR, {message});
				});
			});
	};
}
