import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {inject, injectable} from "inversify";
import {first, isEqual} from "lodash";
import dayjs from "dayjs";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {RoundStatus} from "data/enums";
import type {IPredictionsStore} from "data/stores/predictions/predictions.store";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

export interface ILiveScoreController extends ViewController {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	protected readonly _actions: IChecksumAction;
	private _timerId?: number;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable protected _liveScoreDisposer?: ReturnType<typeof reaction>;
	@observable protected _changesDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _gameWeeksStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	@computed
	private get changes() {
		return Object.keys(this._checksumStore.changedChecksums);
	}

	@computed
	private get gameWeek() {
		return this._predictionsStore.selectedGameWeek;
	}

	@computed
	private get isLive() {
		return isEqual(this.gameWeek?.status, RoundStatus.Playing);
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) return;

		this._isSubscribed = true;
		void this._checksumStore.fetchChecksums();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	private callActions = () => {
		this.changes.forEach((key) => {
			const action = this._actions[key];

			if (action && typeof action === "function") {
				void action();
			}
		});
	};

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			predictorRounds: () => this._gameWeeksStore.fetchRounds(),
		};
	}

	dispose(): void {
		this.unsubscribeLiveScoring();
		this._liveScoreDisposer?.();
		this._changesDisposer?.();
		clearTimeout(this._timerId);
	}

	init(): void {
		this._liveScoreDisposer = reaction(
			() => {
				return this.isLive;
			},
			() => {
				this.unsubscribeLiveScoring();

				if (this.isLive) {
					this.subscribeLiveScoring();
				}
			},
			{fireImmediately: true}
		);

		this._changesDisposer = reaction(() => this.changes, this.callActions, {
			fireImmediately: true,
		});

		const firstMatch = first(this.gameWeek?.games);

		if (firstMatch) {
			const date1 = dayjs(firstMatch.date);
			const date2 = dayjs();

			const milliseconds = date1.diff(date2, "milliseconds") + 2000; // 2 SEC;

			if (milliseconds > 0) {
				this._timerId = setTimeout(() => {
					this.subscribeLiveScoring();
				}, milliseconds) as unknown as number;
			}
		}
	}
}
