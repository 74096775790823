import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGeoBlockProvider} from "data/providers/api/geo_block.provider";

export interface IGeoBlockStore {
	get isAllowed(): boolean;
	fetchGeoBlock(): Promise<void>;
}

@injectable()
export class GeoBlockStore implements IGeoBlockStore {
	@observable private _isAllowed: boolean = false;

	constructor(@inject(Bindings.GeoBlockProvider) private _geoBlockProvider: IGeoBlockProvider) {
		makeAutoObservable(this);
	}

	get isAllowed() {
		return this._isAllowed;
	}

	async fetchGeoBlock() {
		const response = await this._geoBlockProvider.geo_block();

		runInAction(() => {
			this._isAllowed = response.data.success.isAllowed;
		});
	}
}
