import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {ILeague} from "data/providers/api/leagues.api.provider";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {LeagueStatus, LeagueType} from "data/enums";
import {type IUserStore} from "data/stores/user/user.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";

interface IController {
	league: ILeague;
}
export interface ILeagueCardController extends ViewController<IController> {
	readonly i18n: ILocalizationStore;
	get isManagerShow(): boolean;
	get isManager(): boolean;
	get squad(): ISquad | null;
	get link(): string;
}

@injectable()
export class LeagueCardController implements ILeagueCardController {
	@observable _league: ILeague | undefined = undefined;
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get isManagerShow() {
		return Boolean(
			this._league?.class === LeagueType.REGULAR && this._league.leagueManager?.displayName
		);
	}

	get isManager() {
		return this._league?.leagueManager?.userId === this._userStore.user?.id;
	}

	get squad() {
		if (!this._league?.squadId) {
			return null;
		}
		return this._squadsStore.getSquadById(this._league.squadId) || null;
	}

	get link() {
		const aboutOrSettings = this.isManager ? "settings" : "about";
		return this._league?.status === LeagueStatus.SCHEDULED &&
			this._league.class === LeagueType.REGULAR
			? aboutOrSettings
			: "ladder";
	}

	init(param: IController): void {
		this._league = param.league;
	}
}
