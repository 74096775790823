import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {RoundStatus} from "data/enums";

export interface IMonth {
	id: 1;
	name: "May";
	status: RoundStatus;
}

export interface IMonthsStore {
	get list(): IMonth[];

	fetchList(): void;
}

@injectable()
export class MonthsStore implements IMonthsStore {
	@observable private _list: IMonth[] = [];

	get list() {
		return this._list;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action async fetchList() {
		const {data} = await this._jsonProvider.months();

		runInAction(() => {
			this._list = data;
		});
	}
}
