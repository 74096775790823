export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	LEAGUE_JOIN,
	PREDICTIONS_SAVED,
	MAKE_PREDICTIONS,
	CLEAR_PREDICTIONS,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "locked",
	Completed = "completed",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league_invite",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
	CLUB = "club",
}

export enum LeagueStatus {
	COMPLETED = "completed",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum LeaderboardSortOrder {
	Rank = "rank",
	// RoundRank = "roundRank",
	Team = "team",
	Points = "points",
	Margin = "margin",
	AvgPoints = "avgPoints",
	RoundPoints = "roundPoints",
	RoundMargin = "roundMargin",
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Completed = "completed",
	Postponed = "postponed",
}

export enum ScoreType {
	Green,
	Yellow,
	Red,
	Lock,
}

export enum GamePeriod {
	PreMatch = "PreMatch",
	FirstHalf = "FirstHalf",
	HalfTime = "HalfTime",
	SecondHalf = "SecondHalf",
	ExtraFirstHalf = "ExtraFirstHalf",
	ExtraHalfTime = "ExtraHalfTime",
	ExtraSecondHalf = "ExtraSecondHalf",
	FullTimePens = "FullTimePens",
	ShootOut = "ShootOut",
	FullTime = "FullTime",
	Postponed = "Postponed",
	Cancelled = "Cancelled",
}

export enum RankType {
	Overall = "overall",
	Month = "month",
	Round = "round",
}
