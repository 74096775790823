import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface IState {
	name: string;
}

export interface IStatesStore {
	get list(): IState[];

	fetchStates(): void;
}

@injectable()
export class StatesStore implements IStatesStore {
	@observable private _list: IState[] = [];
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get list() {
		return this._list;
	}

	@action
	async fetchStates() {
		const {data} = await this._jsonProvider.states();

		runInAction(() => {
			this._list = data;
		});
	}
}
