import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface INavigationStore {
	get isOpen(): boolean;

	toggleMenu: () => void;
	closeMenu: () => void;
}

@injectable()
export class NavigationStore implements INavigationStore {
	@observable private _isOpen = false;
	constructor() {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._isOpen;
	}

	toggleMenu = () => {
		this._isOpen = !this._isOpen;
		document.body.classList.toggle("overflow", this._isOpen);
	};

	closeMenu = () => {
		this._isOpen = false;
		document.body.classList.remove("overflow");
	};
}
