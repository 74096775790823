/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

const rootElement = document.getElementById("root");
const mobileBreakpoint = "@media (max-width:768px)";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiFilledInput: {
			styleOverrides: {
				root: {
					border: "none",
					":before": {
						borderRadius: "0px !important",
						borderBottom: "0px !important",
					},
					":after": {
						borderRadius: "0px !important",
						borderBottom: "0px !important",
					},
					borderBottom: "2px solid",
					borderImageSource: "linear-gradient(90deg, #250396 0%, #EA00FF 100%)",
					borderImageSlice: 2,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},
			},
		},
		MuiSelect: {
			variants: [
				{
					props: {variant: "filled"},
					style: {
						border: 0,
						borderBottom: "2px solid",
						borderImageSource: "linear-gradient(90deg, #250396 0%, #EA00FF 100%)",
						borderImageSlice: 2,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
						":before": {
							borderRadius: 0,
							borderBottom: 0,
						},
					},
				},
				{
					props: {variant: "outlined"},
					style: {
						fieldset: {
							transition: "all 0.2s ease-out",
							borderRadius: 4 /*1*/,
							border: "1px solid transparent" /*2*/,
							background: "linear-gradient(90deg, #250396 0%, #EA00FF 100%)",
							mask: `
								linear-gradient(#fff 0 0) padding-box,
								linear-gradient(#fff 0 0)
							`,
							maskComposite: "exclude",
						},
					},
				},
			],
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					fontWeight: "700",
					fontSize: "16px",
					textTransform: "none",
					height: "48px",
					borderRadius: " 100px",
				},
				textPrimary: {
					background: "linear-gradient(270deg, #5618EB 0%, #2DA7D1 100%)",
					backgroundClip: "text",
					textFillColor: "transparent",
					textDecoration: "underline",
					":hover": {
						background: "linear-gradient(270deg, #5618EB 0%, #2DA7D1 100%)",
						backgroundClip: "text",
						textFillColor: "transparent",
					},
				},
				containedPrimary: {
					color: "#FFF",
					borderRadius: 100,
					background: "linear-gradient(270deg, #5618EB 0%, #2DA7D1 100%)",
					":hover": {
						color: "#161125",
						background: "linear-gradient(232deg, #60D7FB 0%, #00E895 100%)",
					},
					":disabled": {
						color: "#636366",
						background: "#BFFFE8",
					},
				},
				outlinedPrimary: {
					borderColor: "blue",
					color: "blue",
					":hover": {
						borderColor: "blue",
					},
					":disabled": {
						border: "none",
					},
				},
				outlined: {
					borderWidth: "2px",
					fontWeight: 700,
					":hover": {
						borderWidth: "2px",
					},
				},
				containedSecondary: {
					color: "#FFF",
					borderRadius: 100,
					background: "linear-gradient(96deg, #F03 6.78%, #9913B9 52.92%, #5618EB 100%)",
					":hover": {
						color: "#161125",
						background: "linear-gradient(270deg, #FF32A8 0%, #D93CFF 100%)",
					},
					":disabled": {
						color: "#C7C7CC",
						background: "#4D3663",
					},
				},
				containedError: {
					background: "red",
					color: "#FFF",
				},
				outlinedSecondary: {
					borderColor: "#9133DB",
					color: "#161125",
					":hover": {
						color: "#161125",
						borderColor: "#9133DB",
						background: "linear-gradient(270deg, #FF32A8 0%, #D93CFF 100%);",
					},
				},
			},
			variants: [
				{
					props: {className: "with-underline"},
					style: {
						borderBottom: "1px solid",
						borderImageSource: "linear-gradient(270deg, #5618EB 0%, #2DA7D1 100%)",
						borderImageSlice: 1,
						borderRadius: 0,
						height: "auto",
						padding: 0,
					},
				},
			],
		},

		MuiPaper: {
			variants: [
				{
					props: {variant: "elevation"},
					style: {
						border: "1px solid #C7C7CC",
						padding: "20px",
						boxShadow: "none",
						borderRadius: "4px",
					},
				},
			],
		},

		MuiMenu: {
			styleOverrides: {
				root: {
					"& .MuiPaper-root": {
						padding: 0,
					},
				},
			},
		},

		MuiTab: {
			styleOverrides: {
				root: {
					background: "#EFEFEF",
					color: "#636366",
					fontSize: "14px",
					fontWeight: 700,
					width: "auto",
					height: "48px",
					minWidth: "105px",
					padding: "0 5px",
					textTransform: "capitalize",
					"&.Mui-selected": {
						background: "white",
						color: "#7600D2",
						borderBottom: "1px solid white",
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					gap: "1px",
					borderRadius: "4px 4px 0px 0px",
					borderTop: "1px solid #C7C7CC",
					borderRight: "1px solid #C7C7CC",
					borderLeft: "1px solid  #C7C7CC",
					background: "#FFF",
					position: "relative",
					top: "1px",
					height: "48px",
					width: "fit-content",
					maxWidth: "100%",
					[mobileBreakpoint]: {
						borderRadius: "0",
					},
				},
				flexContainer: {
					gap: "1px",
				},
			},
		},

		MuiModal: {
			defaultProps: {
				container: rootElement,
			},
		},
	},
};
