import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IMobileMenuController} from "views/components/mobile_menu/mobile_menu.controller";
import {Collapse, List, ListItem, ListItemButton} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {Link, NavLink} from "react-router-dom";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {ExpandLess, ExpandMore, LoginRounded, OpenInNewRounded} from "@mui/icons-material";
import {PureButton} from "views/components/pure_button/pure_button.component";

const MobileMenuContainer = styled.div`
	background: #2d1347;
	height: 0;
	width: 100%;
	transition: all 0.3s linear;
	z-index: 10;

	&.open {
		height: 100%;
	}

	button,
	a {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		display: flex;
		justify-content: flex-start;
		width: 100%;
		padding: 0 16px;
		align-items: center;
		height: 44px;
		&.active {
			background: #520093;
		}
	}
	ul {
		padding: 0;
		display: flex;
		flex-direction: column;
	}
	> ul {
		overflow: auto;
	}
	li {
		padding: 0;

		&.last {
			padding: 0;
			margin-top: auto;
			width: 100%;
			ul {
				width: 100%;
			}
		}
	}

	.open {
		background: #520093;
	}

	nav {
		width: 100%;
	}

	.pure-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.sub-item {
		padding: 0;
		a {
			padding-left: 32px;
		}
	}
`;

const Divider = styled.li`
	height: 1px;
	width: 100%;
	display: flex;
	background: linear-gradient(90deg, #250396 0%, #ea00ff 100%);
`;

const Last = styled.div`
	background: #2d1347;
	--defaultPaddingBottom: 12px;
	margin-bottom: var(--defaultPaddingBottom);
	/* iOS Safari 11.2, Safari 11 */
	margin-bottom: calc(
		var(--defaultPaddingBottom) + constant(safe-area-inset-bottom, var(--defaultPaddingBottom))
	);
	/* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
	margin-bottom: calc(
		var(--defaultPaddingBottom) + env(safe-area-inset-bottom, var(--defaultPaddingBottom))
	);

	.pure-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	button,
	a {
		color: #fff;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		width: 100%;
		padding: 0 16px;
		height: 44px;
		&.active {
			background: #520093;
		}
	}
`;

const Nav = styled.div`
	flex: 1 1 0%;
	overflow: auto;
`;

const LeaguesMenu: React.FC<{closeMenu: () => void}> = ({closeMenu}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Fragment>
			<ListItem disablePadding>
				<PureButton className={`pure-button ${open ? "open" : ""}`} onClick={handleClick}>
					{i18n.t("hamburger.leagues.copy")} {open ? <ExpandLess /> : <ExpandMore />}
				</PureButton>
			</ListItem>
			<Divider />
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/leagues"} end onClick={closeMenu}>
								{i18n.t("hamburger.my_leagues.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
					<Divider />
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/leagues/create"} end onClick={closeMenu}>
								{i18n.t("hamburger.create_leagues.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
					<Divider />
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/leagues/join"} end onClick={closeMenu}>
								{i18n.t("hamburger.join_leagues.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
				</List>
			</Collapse>
		</Fragment>
	);
};
const HelpMenu: React.FC<{closeMenu: () => void}> = ({closeMenu}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Fragment>
			<ListItem disablePadding>
				<PureButton className={`pure-button ${open ? "open" : ""}`} onClick={handleClick}>
					{i18n.t("hamburger.help.copy")} {open ? <ExpandLess /> : <ExpandMore />}
				</PureButton>
			</ListItem>
			<Divider />
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/help/game-guidelines"} end onClick={closeMenu}>
								{i18n.t("hamburger.guidelines.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
					<Divider />
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/help/faqs"} end onClick={closeMenu}>
								{i18n.t("hamburger.faqs.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
					<Divider />
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/help/t&cs"} end onClick={closeMenu}>
								{i18n.t("hamburger.terms.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
					<Divider />
					<ListItemButton className="sub-item">
						<ListItem disablePadding>
							<NavLink to={"/help/contact-us"} end onClick={closeMenu}>
								{i18n.t("hamburger.contact_us.copy")}
							</NavLink>
						</ListItem>
					</ListItemButton>
				</List>
			</Collapse>
		</Fragment>
	);
};

export const MobileMenu: React.FC = observer(() => {
	const {closeMenu, isOpen, i18n, isAuth, logout} = useViewController<IMobileMenuController>(
		Bindings.MobileMenuController
	);

	return (
		<Fragment>
			<MobileMenuContainer className={`md:hidden ${isOpen ? "open" : ""}`}>
				<Nav>
					<List className="w-full">
						<Exist when={isAuth}>
							<Fragment>
								<ListItem disablePadding>
									<NavLink to={"/predictor"} end onClick={closeMenu}>
										{i18n.t("hamburger.my_predictions.copy")}
									</NavLink>
								</ListItem>
								<Divider />
								<LeaguesMenu closeMenu={closeMenu} />
								<Divider />
								<ListItem disablePadding>
									<NavLink to={"/rankings"} end onClick={closeMenu}>
										{i18n.t("hamburger.rankings.copy")}
									</NavLink>
								</ListItem>
								<Divider />
							</Fragment>
						</Exist>
						<ListItem disablePadding>
							<NavLink to={"/prizes"} end onClick={closeMenu}>
								{i18n.t("hamburger.prizes.copy")}
							</NavLink>
						</ListItem>
						<Divider />
						<HelpMenu closeMenu={closeMenu} />
						<Exist when={isAuth}>
							<ListItem disablePadding>
								<NavLink to={"/my-account"} end onClick={closeMenu}>
									{i18n.t("hamburger.my_account.copy")}
								</NavLink>
							</ListItem>
							<Divider />
						</Exist>
					</List>
				</Nav>
			</MobileMenuContainer>

			<Last className={`md:hidden`}>
				<List>
					<ListItem disablePadding>
						<Link
							className="pure-button justify-start gap-2"
							target={"_blank"}
							to={
								"https://sooka.my/en/watch/sponsored/details/malaysian-football-league/1498181"
							}>
							<OpenInNewRounded />{" "}
							{i18n.t("navigation.sooka_highlight.copy", "MFL Highlights")}
						</Link>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<Link
							className="pure-button justify-start gap-2"
							target={"_blank"}
							to={"https://sooka.my/m/about-us/"}>
							<OpenInNewRounded />{" "}
							{i18n.t("navigation.what_is_sooka.copy", "What is sooka?")}
						</Link>
					</ListItem>
					<Divider />
					<ListItem disablePadding>
						<Link
							className="pure-button justify-start gap-2"
							target={"_blank"}
							to={"https://sooka.my/en/"}>
							<OpenInNewRounded />{" "}
							{i18n.t("navigation.sooka_home.copy", "Go To sooka")}
						</Link>
					</ListItem>
					<Divider />

					<Exist when={isAuth}>
						<ListItem disablePadding>
							<PureButton
								className="pure-button justify-start gap-2"
								onClick={logout}>
								<LoginRounded /> {i18n.t("hamburger.logout.copy")}
							</PureButton>
						</ListItem>
						<Divider />
					</Exist>
				</List>
			</Last>
		</Fragment>
	);
});
