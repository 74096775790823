/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 *
 * More info on custom theming for Typography can be found here
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
import {ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		body2Bold: React.CSSProperties;
		body3: React.CSSProperties;
		body4: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		body2Bold?: React.CSSProperties;
		body3?: React.CSSProperties;
		body4?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		body2Bold: true;
		body3: true;
		body4: true;
	}
}

export const typographyTheme: ThemeOptions = {
	typography: {
		allVariants: {
			fontFamily: "var(--fontFamilyBase)",
			color: "#161125",
		},

		h1: {
			fontWeight: 700,
		},
		h2: {
			fontWeight: 700,
		},
		h3: {
			fontWeight: 700,
		},
		h4: {
			fontWeight: 700,
		},
		h5: {
			fontWeight: 700,
		},
		h6: {
			fontWeight: 700,
		},
		body2Bold: {
			fontSize: "16px",
			fontWeight: 700,
		},
		body3: {
			fontSize: "14px",
			lineHeight: "140%",
		},
		body4: {
			fontFamily: "var(--fontFamilySecond)",
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body3: "p", // Map the new variant to render a 'p' tag by default
					body4: "p", // Map the new variant to render a 'p' tag by default
				},
			},
		},
	},
};
