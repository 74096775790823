import {action, computed, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IRankings} from "data/providers/api/leagues.api.provider";
import {LeaderboardSortOrder, ModalType, RankType, RequestState, SortOrder} from "data/enums";
import type {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {SyntheticEvent} from "react";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {isEqual, last} from "lodash";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IMonth, IMonthsStore} from "data/stores/months/months.store";

export interface IRankingsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;

	get rounds(): IRound[];
	get rankType(): RankType;
	get months(): IMonth[];

	get selectedRound(): number;
	get selectedMonth(): number;

	get table(): IRankings["leaderboard"];

	get showUserRank(): boolean;

	get isOverall(): boolean;

	get orderBy(): LeaderboardSortOrder;

	get orderDirection(): SortOrder;
	get isLeaguePresenceRequestLoading(): boolean;
	get userTeamId(): number | undefined;

	changeRound: (event: SelectChangeEvent<unknown>) => void;
	changeMonth: (event: SelectChangeEvent<unknown>) => void;
	loadMoreRanks: () => void;
	handleOrder: (e: SyntheticEvent<HTMLButtonElement>) => void;
	changeRankType: (event: SelectChangeEvent<unknown>) => void;
}

@injectable()
export class RankingsController implements IRankingsController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _rankType: RankType = RankType.Overall;
	@observable private _selectedRound: number = 0;
	@observable private _monthId: number = 0;
	@observable private _roundChangeDisposer?: ReturnType<typeof reaction>;
	@observable private _sortChangeDisposer?: ReturnType<typeof reaction>;
	@observable private _rankTypeChangeDisposer?: ReturnType<typeof reaction>;
	@observable private _monthChangeDisposer?: ReturnType<typeof reaction>;
	@observable private _orderBy = LeaderboardSortOrder.Rank;
	@observable private _orderDirection = SortOrder.ASC;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.MonthsStore) private _monthStore: IMonthsStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore
	) {
		makeAutoObservable(this);
	}

	get rankType() {
		return this._rankType;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	@computed
	get rounds() {
		return this._roundsStore.listForLadder;
	}

	get months() {
		return this._monthStore.list;
	}

	get selectedRound() {
		return this._selectedRound;
	}

	get selectedMonth() {
		return this._monthId;
	}

	get table() {
		return this._rankingsStore.rankings;
	}

	get showUserRank(): boolean {
		return (
			Boolean(this.table.user) &&
			!this.table.rankings.find((rank) => rank.teamId === this.table.user?.teamId)
		);
	}

	get userTeamId(): number | undefined {
		return this.table.user?.teamId;
	}

	get isOverall() {
		return this._rankType === RankType.Overall;
	}

	get orderBy() {
		return this._orderBy;
	}

	get orderDirection() {
		return this._orderDirection;
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get monthId() {
		return this._monthId;
	}

	@action
	loadMoreRanks = async () => {
		this._requestState = RequestState.PENDING;

		if (this.rankType === RankType.Round) {
			await this._rankingsStore
				.fetchMoreRoundRankings({
					roundId: this.selectedRound,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess);
		} else if (this.rankType === RankType.Month) {
			await this._rankingsStore
				.fetchMoreRoundRankings({
					monthId: this.monthId,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		} else {
			await this._rankingsStore
				.fetchMoreOverallRankings({
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess);
		}
	};

	@action changeRound = (event: SelectChangeEvent<unknown>) => {
		this._selectedRound = Number(event.target.value);
	};
	@action changeMonth = (event: SelectChangeEvent<unknown>) => {
		this._monthId = Number(event.target.value);
	};

	@action changeRankType = (event: SelectChangeEvent<unknown>) => {
		this._rankType = event.target.value as RankType;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action handleOrder = (e: SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		const isSelected = value === this._orderBy;

		if (isSelected) {
			this._orderDirection =
				this._orderDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		} else {
			this._orderBy = value as LeaderboardSortOrder;

			this._orderDirection = SortOrder.ASC;
		}
	};

	@action
	async init() {
		this._requestState = RequestState.PENDING;

		await Promise.all([
			this._roundsStore.fetchRounds(),
			this._monthStore.fetchList(),
			this._rankingsStore.fetchOverallRankings({
				orderBy: this.orderBy,
				orderDir: this.orderDirection,
			}),
		])
			.then(this.onSuccess)
			.catch(this.onError);
		runInAction(() => {
			const lastRoundId = last(this._roundsStore.listForLadder)?.id;
			if (lastRoundId) {
				this._selectedRound = lastRoundId;
			}
			const lastMonth = last(this.months)?.id;

			if (lastMonth) {
				this._monthId = lastMonth;
			}
		});

		this._roundChangeDisposer = reaction(
			() => this._selectedRound,
			() => {
				void this.fetchLadder();
			}
		);

		this._monthChangeDisposer = reaction(
			() => this._monthId,
			() => {
				void this.fetchLadder();
			}
		);

		this._rankTypeChangeDisposer = reaction(
			() => this._rankType,
			() => {
				void this.fetchLadder();
			}
		);

		this._sortChangeDisposer = reaction(
			() => [this.orderBy, this.orderDirection],
			() => {
				void this.fetchLadder();
			}
		);
	}

	@action
	fetchLadder = async () => {
		this._requestState = RequestState.PENDING;

		if (this.rankType === RankType.Round) {
			await this._rankingsStore
				.fetchRoundRankings({
					roundId: this.selectedRound,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		} else if (this.rankType === RankType.Month) {
			await this._rankingsStore
				.fetchRoundRankings({
					monthId: this.monthId,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		} else {
			await this._rankingsStore
				.fetchOverallRankings({
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		}
	};

	dispose() {
		this._roundChangeDisposer?.();
		this._sortChangeDisposer?.();
		this._rankTypeChangeDisposer?.();
		this._monthChangeDisposer?.();
	}
}
