import styled from "@emotion/styled";
import {useSwiper} from "swiper/react";
import React from "react";
import {PureButton} from "views/components/pure_button/pure_button.component";

export const TutorialTitle = styled.h3`
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 135%;
	color: #000;
`;
export const TutorialDesc = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	color: #000;
`;

export const SlideNextButton: React.FC = () => {
	const swiper = useSwiper();

	return <PureButton className="swiper-button-next" onClick={() => swiper.slideNext()} />;
};

export const SlidePrevButton: React.FC = () => {
	const swiper = useSwiper();

	return <PureButton className="swiper-button-prev" onClick={() => swiper.slidePrev()} />;
};

export const SlideDot: React.FC<{activeStep: number; index: number}> = ({activeStep, index}) => {
	const swiper = useSwiper();
	const onClick = () => swiper.slideTo(index);
	return (
		<PureButton
			className={`swiper-pagination-bullet active-index-${activeStep - 1} index-${index} ${index === activeStep - 1 ? "swiper-pagination-bullet-active" : ""}`}
			onClick={onClick}
		/>
	);
};
