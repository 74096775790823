import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {ChangeEvent, SyntheticEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";
import {SelectChangeEvent} from "@mui/material";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import dayjs from "dayjs";

interface IRegistrationFormElement extends HTMLFormElement {
	isSooka: HTMLInputElement;
	displayName: HTMLInputElement;
	email: HTMLInputElement;
	confirmEmail: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	terms: HTMLInputElement;
	phone: HTMLInputElement;
	squad: HTMLSelectElement;
	birthday: HTMLInputElement;
	notifications: HTMLInputElement;
}

export interface IRegistrationController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get isSooka(): boolean | undefined;
	get displayName(): string;
	get squads(): ISquad[];
	get selectedSquad(): string;
	get birthday(): string;
	get isPasswordVisible(): boolean;
	get isConfirmPasswordVisible(): boolean;
	get phone(): string | undefined;

	handleChangePhone: (value: string) => void;
	handleChangeSquad: (event: SelectChangeEvent<unknown>) => void;

	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleValidatePassword: (event: ChangeEvent<HTMLInputElement>) => void;
	handleRegisteredUserChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleBirthChange: (event: ChangeEvent<HTMLInputElement>) => void;
	toggleViewPassword: () => void;
	toggleViewConfirmPassword: () => void;
}

@injectable()
export class RegistrationController implements IRegistrationController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";
	@observable private _selectedSquad = "";
	@observable private _birthday: string = "";
	@observable private _isPasswordVisible: boolean = false;
	@observable private _isConfirmPasswordVisible: boolean = false;
	@observable private _isSooka: string | undefined = undefined;
	@observable private _phone: string | undefined = undefined;

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get displayName(): string {
		return this._displayName;
	}

	get squads() {
		return this._squadsStore.list;
	}

	get selectedSquad() {
		return this._selectedSquad;
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get birthday() {
		return this._birthday;
	}

	get phone() {
		return this._phone;
	}

	get isPasswordVisible() {
		return this._isPasswordVisible;
	}
	get isConfirmPasswordVisible() {
		return this._isConfirmPasswordVisible;
	}

	get isSooka() {
		return this._isSooka === "yes";
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {
			isSooka,
			displayName,
			email,
			confirmEmail,
			password,
			confirmPassword,
			terms,
			notifications,
			phone,
			squad,
			birthday,
		} = event.currentTarget;

		const errorInvalidEmail = this.i18n.t(
			"registration.email.error",
			"Please provide a valid email address"
		);
		const errorInvalidConfirmEmail = this.i18n.t(
			"registration.confirm_email.error",
			"Confirm email address is invalid"
		);
		const errorInvalidUsername = this.i18n.t(
			"registration.display_name.error",
			"Please provide your display name"
		);
		const errorInvalidPassword = this.i18n.t(
			"registration.password.error",
			PASSWORD_REQUIREMENTS
		);
		const errorInvalidTerms = this.i18n.t(
			"registration.terms.error",
			"Please accept Terms & Conditions"
		);
		const errorEmailsMismatch = this.i18n.t(
			"registration.email.mismatch_error",
			"Emails do not match"
		);
		const errorPasswordsMismatch = this.i18n.t(
			"registration.password.mismatch_error",
			"Passwords do not match"
		);
		const errorNoPhoneSelected = this.i18n.t(
			"registration.phone.error",
			"Please enter phone number"
		);
		const errorNoSquadSelected = this.i18n.t("registration.squad.error", "Please select squad");
		const errorNoBirthdaySelected = this.i18n.t(
			"registration.birthday.error",
			"Please select DoB"
		);

		const dobValidator = () => {
			const maxDate = dayjs().subtract(18, "years").get("year");
			const minDate = dayjs().subtract(100, "years").get("year");
			const dob = Number(birthday.value);

			return dob && dob >= minDate && dob <= maxDate;
		};

		const validateList = [
			{field: email, error: errorInvalidEmail, place: "email"},
			{field: confirmEmail, error: errorInvalidConfirmEmail, place: "confirmEmail"},
			{field: displayName, error: errorInvalidUsername, place: "displayName"},
			{field: password, error: errorInvalidPassword, place: "password"},
			{field: phone, error: errorNoPhoneSelected, place: "phone"},
			{field: squad, error: errorNoSquadSelected, place: "squad"},
			{
				field: birthday,
				error: errorNoBirthdaySelected,
				place: "birthday",
				validator: dobValidator,
			},
			{field: terms, error: errorInvalidTerms, place: "terms"},
		];

		const hasError = validateList.find(
			({field, error, place, validator}) =>
				!(validator || field.checkValidity).call(field) && this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (email.value !== confirmEmail.value) {
			return this.reportError(errorEmailsMismatch, "confirmEmail");
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError(errorPasswordsMismatch, "confirmPassword");
		}

		const payload = {
			isSooka: Boolean(isSooka.checked),
			displayName: displayName.value,
			email: email.value,
			password: password.value,
			phone: phone.value,
			teamSupported: Number(squad.value),
			dob: Number(birthday.value),
			isNotificationsEnabled: Boolean(notifications.checked),
		};

		this._requestState = RequestState.PENDING;
		this._userStore.register(payload).catch(this.onError);
	};
	@action clearErrors = () => {
		this._errorMsg = null;
		this._errorPlace = "";
	};

	@action handleChangePhone = (value: string) => {
		this._phone = value;

		this.clearErrors();
	};

	@action handleChangeSquad = (event: SelectChangeEvent<unknown>) => {
		this._selectedSquad = event.target.value as string;

		this.clearErrors();
	};

	@action toggleViewPassword = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	@action toggleViewConfirmPassword = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		if (event.target.name !== "password") {
			this._errorMsg = null;
			this._errorPlace = "";
		}
	};

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action handleValidatePassword = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			const errorInvalidPassword = this.i18n.t(
				"registration.password.error",
				PASSWORD_REQUIREMENTS
			);
			this.reportError(errorInvalidPassword, "password");
		}
	};

	@action handleRegisteredUserChange = (event: ChangeEvent<HTMLInputElement>) => {
		this._isSooka = event.target.value;
	};

	@action handleBirthChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length > 4) {
			return;
		}

		this._birthday =
			event.target.value === "" || isNaN(Number(event.target.value))
				? ""
				: event.target.value;
	};

	async init() {
		await this._squadsStore.fetchSquads().catch(this.onError);
	}
}
