import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type IPredictionsApiProvider} from "data/providers/api/predictions.api.provider";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IPredictorController extends ViewController {
	get isLoaded(): boolean;
	get isBlocking(): boolean;
}

@injectable()
export class PredictorController implements IPredictorController {
	@observable private _isLoaded: boolean = false;
	@observable _userFlowDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.RoundsStore) private _gameWeeksStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.PredictionsApiProvider)
		private _predictionsApiProvider: IPredictionsApiProvider,
		@inject(Bindings.LocalizationStore) public _i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isLoaded() {
		return this._isLoaded;
	}
	get isBlocking() {
		return this._predictionsStore.hasChanges;
	}

	dispose() {
		this._userFlowDisposer?.();
	}

	async init() {
		try {
			await Promise.all([
				this._gameWeeksStore.fetchRounds(),
				this._squadsStore.fetchSquads(),
			]);

			await Promise.all([
				this.fetchPredictions(),
				this._predictionsStore.fetchGameBar(this._predictionsStore.selectedGameWeek?.id),
			]);

			if (!this._userStore.user?.isTutorialWatched) {
				this._tutorialStore.setIsOpen(true);
			}

			runInAction(() => {
				this._isLoaded = true;
			});
		} catch (err) {
			runInAction(() => {
				const message = extractErrorMessage(err as AxiosError<IApiResponse>);
				this._modalsStore.showModal(ModalType.ERROR, {message});
			});
		}
	}

	@action
	private fetchPredictions = async () => {
		try {
			const selectedGameWeek = this._predictionsStore.selectedGameWeek;

			if (!selectedGameWeek) {
				return;
			}

			await this._predictionsStore.fetchPredictions(selectedGameWeek.id);
		} catch (err) {
			runInAction(() => {
				const message = extractErrorMessage(err as AxiosError<IApiResponse>);
				this._modalsStore.showModal(ModalType.ERROR, {message});
			});
		}
	};
}
