import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {isNumber} from "lodash";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {ViewController} from "data/types/structure";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IUserStore} from "data/stores/user/user.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {SelectChangeEvent} from "@mui/material";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";

export interface IPredictorSummaryBarController extends ViewController {
	i18n: ILocalizationStore;
	changeSelectedGW: (e: SelectChangeEvent) => void;
	autoFillHandler: () => void;
	clearHandler: () => void;
	saveHandler: () => void;
	onRoundStarted: () => void;
	openTutorial: () => void;

	get listForSelect(): IRound[];
	get selectedGameWeek(): IRound | undefined;
	get isShowAutoFillButton(): boolean;
	get isShowClearButton(): boolean;
	get isShowButtonsBlock(): boolean;
	get isDisabledAutoFillButton(): boolean;
	get isDisabledClearButton(): boolean;
	get gameWeekPoints(): string | number;
	get gameWeekRank(): string | number;
	get totalPoints(): string | number;
	get isShowSaveButton(): boolean;
	get isOpen(): boolean;

	onCancel: () => void;
	onSuccess: () => void;
}

@injectable()
export class PredictorSummaryBarController implements IPredictorSummaryBarController {
	@observable private _isOpen: boolean = false;
	@observable private _tempRoundId: number | null = null;

	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _gameWeeksStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	get listForSelect() {
		return this._gameWeeksStore.list.filter((round: IRound) => {
			return round.games.length > 0;
		});
	}

	get selectedGameWeek() {
		return this._predictionsStore.selectedGameWeek;
	}

	get isShowAutoFillButton() {
		return this._predictionsStore.isShowAutoFillButton;
	}

	get isShowClearButton() {
		return this._predictionsStore.isShowClearButton;
	}

	get isShowSaveButton() {
		return this._predictionsStore.isShowSaveButton;
	}

	get isShowButtonsBlock() {
		return this.isShowAutoFillButton || this.isShowClearButton;
	}

	get isDisabledClearButton() {
		return this._predictionsStore.isDisabledAnyActions;
	}

	get isDisabledAutoFillButton() {
		return this._predictionsStore.isDisabledAnyActions;
	}

	get gameWeekPoints() {
		const gameWeekPoints = this._predictionsStore.gameBar.gameweekPoints;
		return isNumber(gameWeekPoints) ? gameWeekPoints : "--";
	}

	get gameWeekRank() {
		const gameWeekRank = this._predictionsStore.gameBar.gameweekRank;
		return isNumber(gameWeekRank) ? gameWeekRank : "--";
	}

	get totalPoints() {
		const points = this._predictionsStore.gameBar.points;
		return isNumber(points) ? points : "--";
	}

	get isOpen() {
		return this._isOpen;
	}

	changeSelectedGW = (e: SelectChangeEvent) => {
		const gwId = Number(e.target.value);

		if (this._predictionsStore.hasChanges) {
			this._isOpen = true;
			this._tempRoundId = gwId;
			return;
		}

		this.changeRound(gwId);
	};

	@action changeRound(gwId: number) {
		this._predictionsStore.setSelectedRound(gwId);

		if (this._userStore.isAuthorized) {
			this._predictionsStore.fetchPredictions(gwId).catch(this.onError);
			this._predictionsStore.fetchGameBar(gwId).catch(this.onError);
		}
	}

	@action autoFillHandler = () => {
		const selectedGameWeek = this._predictionsStore.selectedGameWeek;

		if (!selectedGameWeek) {
			return;
		}

		void this._predictionsStore.autoFill(selectedGameWeek.id).catch((err) => {
			runInAction(() => {
				const message = extractErrorMessage(err as AxiosError<IApiResponse>);
				this._modalsStore.showModal(ModalType.ERROR, {message});
			});
		});
	};

	@action
	saveHandler = () => {
		const selectedGameWeek = this._predictionsStore.selectedGameWeek;

		if (!this._predictionsStore.predictionsForSave.length || !selectedGameWeek) {
			return;
		}

		this._predictionsStore
			.save(selectedGameWeek.id)
			.then(() => {
				runInAction(() => {
					this._modalsStore.showModal(ModalType.PREDICTIONS_SAVED);
				});
			})
			.catch((err) => {
				runInAction(() => {
					const message = extractErrorMessage(err as AxiosError<IApiResponse>);
					this._modalsStore.showModal(ModalType.ERROR, {message});
				});
			});
	};

	clearHandler = () => {
		this._modalsStore.showModal(ModalType.CLEAR_PREDICTIONS);
	};

	onRoundStarted = () => {
		// TODO: add handler
	};

	openTutorial = () => {
		this._tutorialStore.setIsOpen(true);
	};

	onCancel = () => {
		this._isOpen = false;
		this._tempRoundId = null;
	};
	onSuccess = () => {
		this._isOpen = false;
		this.changeRound(this._tempRoundId as number);
		this._tempRoundId = null;
	};

	private onError = (err: AxiosError<IApiResponse>) => {
		const message = extractErrorMessage(err);
		this._modalsStore.showModal(ModalType.ERROR, {
			message,
		});
	};
}
