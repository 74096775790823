import React, {Fragment} from "react";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {Exist} from "views/components/exist/exist.component";
import {Tutorial} from "views/components/tutorial/tutorial.component";

export const Wrapper: React.FC<{children: React.ReactNode}> = ({children}) => {
	const IS_SECRET_PASSED = SecretGateController.IS_SECRET_PASSED;
	return (
		<Fragment>
			<Exist when={IS_SECRET_PASSED}>
				<Header />
			</Exist>

			{children}
			<Exist when={IS_SECRET_PASSED}>
				<Footer />
			</Exist>
			<Tutorial />
		</Fragment>
	);
};
