import styled from "@emotion/styled";
import {css} from "@mui/material/styles";
import {NavLink} from "react-router-dom";

export const linkStyles = css`
	display: inline-flex;
	color: var(--reviewColor);
	outline: none;
	text-decoration: none;
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 0.5s;
	cursor: pointer;
	background-size: 0 1px;

	&:hover,
	&:focus {
		background-size: 100% 1px;
	}
`;

export const ExternalLink = styled.a``;

ExternalLink.defaultProps = {
	target: "_blank",
	rel: "noreferrer noopener",
};

export const InternalLink = styled(NavLink)`
	${linkStyles};
`;
