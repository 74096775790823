import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import {
	CloseBlock,
	ModalContent,
} from "views/components/modals/modal_content/modal_content.components";

const Icon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<Icon>
					<ErrorIcon />
				</Icon>
				<Typography>
					{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
				</Typography>
				<Exist when={modalContent?.message}>
					<Typography>{modalContent?.message}</Typography>
				</Exist>
			</ModalContent>
		</Modal>
	);
});
