import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, SyntheticEvent} from "react";
import {ModalType, RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";

import type {IModalsStore} from "data/stores/modals/modals.store";

interface IChangePwForm extends HTMLFormElement {
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IChangePasswordController extends ViewController {
	readonly i18n: ILocalizationStore;

	get error(): Record<string, string> | null;
	get showSuccessBanner(): boolean;
	get successCopy(): string;
	get isPasswordVisible(): boolean;
	get isConfirmPasswordVisible(): boolean;
	get isPasswordUpdateDisabled(): boolean;
	get passwordUpdateCopy(): string;
	get fieldLabel(): Record<string, string>;
	get isRequestStatePending(): boolean;

	handleClearErrorOnChange: () => void;
	toggleViewPassword: () => void;
	toggleViewConfirmPassword: () => void;
	handleValidatePassword: (event: ChangeEvent<HTMLInputElement>) => void;
	handlePasswordSubmit: (e: SyntheticEvent<IChangePwForm>) => void;
	handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleConfirmPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class ChangePasswordController implements IChangePasswordController {
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _successCopy = "";
	@observable private _passwordRequestState = RequestState.IDLE;
	@observable private _isPasswordVisible = false;
	@observable private _isConfirmPasswordVisible = false;
	@observable private _password = "";
	@observable private _confirmPassword = "";
	@observable private _successRequestStateDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get fieldLabel() {
		const labelPassword = this.i18n.t("my_account.password.label", "Enter New Password");
		const labelConfirmPassword = this.i18n.t(
			"my_account.confirm_password.label",
			"Confirm New Password"
		);
		return {
			password: labelPassword,
			confirmPassword: labelConfirmPassword,
		};
	}

	get passwordRequestState() {
		return this._passwordRequestState;
	}

	get isRequestStatePending() {
		return this.passwordRequestState === RequestState.PENDING;
	}

	get passwordUpdateCopy() {
		if (this.passwordRequestState === RequestState.SUCCESS) {
			return this.i18n.t("my_account.updated.button", "Updated");
		}

		return this.i18n.t("my_account.update.button", "Update");
	}

	get isPasswordUpdateDisabled() {
		if (
			this._password === "" ||
			this._confirmPassword === "" ||
			this._password !== this._confirmPassword
		) {
			return true;
		}

		return Boolean(
			this.isRequestStatePending || this.error?.confirmPassword || this.error?.password
		);
	}

	get showSuccessBanner() {
		return Boolean(
			isEqual(this._passwordRequestState, RequestState.SUCCESS) && this.successCopy
		);
	}

	get successCopy() {
		return this._successCopy;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isPasswordVisible() {
		return this._isPasswordVisible;
	}

	get isConfirmPasswordVisible() {
		return this._isConfirmPasswordVisible;
	}

	@action toggleViewPassword = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	@action toggleViewConfirmPassword = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		this._password = value;
	};

	@action handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		this._confirmPassword = value;
	};

	@action handleValidatePassword = (event: ChangeEvent<HTMLInputElement>) => {
		this.handlePasswordChange(event);

		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			const errorInvalidPassword = this.i18n.t(
				"my_account.password.error",
				PASSWORD_REQUIREMENTS
			);

			this.reportError(errorInvalidPassword, "password");
		}
	};

	@action handlePasswordSubmit = (e: SyntheticEvent<IChangePwForm>) => {
		e.preventDefault();
		this.handleClearErrorOnChange();

		this._passwordRequestState = RequestState.PENDING;
		const {password, confirmPassword} = e.currentTarget;

		const errorInvalidPassword = this.i18n.t(
			"my_account.password.error",
			PASSWORD_REQUIREMENTS
		);
		const errorPasswordsMismatch = this.i18n.t(
			"registration.password.mismatch_error",
			"Passwords do not match"
		);

		const validateList = [
			{
				field: password,
				error: errorInvalidPassword,
				place: "password",
			},
			{
				field: confirmPassword,
				error: errorPasswordsMismatch,
				place: "confirmPassword",
				checkValidity: () => isEqual(password.value, confirmPassword.value),
			},
		];

		const hasError = validateList.find(({field, error, place, checkValidity}) => {
			const isValid = checkValidity?.() ?? field.checkValidity();
			return isValid ? false : this.reportError(error, place);
		});

		if (hasError) {
			return;
		}

		// this._userStore.update({password: password.value}).then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._passwordRequestState = RequestState.SUCCESS;
		this._successCopy = this.i18n.t("my_account.psw_updated_banner.copy");
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._passwordRequestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: this._errorMsg,
		});
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
	};

	init() {
		this._successRequestStateDisposer = reaction(
			() => this._passwordRequestState,
			() => {
				if (!isEqual(this._passwordRequestState, RequestState.SUCCESS)) {
					return;
				}

				this._passwordRequestState = RequestState.IDLE;
				this._successCopy = "";
				this._confirmPassword = "";
				this._password = "";
			},
			{
				delay: 10000,
			}
		);
	}

	dispose() {
		this._successRequestStateDisposer?.();
	}
}
