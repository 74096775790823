import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {
	type IRegistrationController,
	RegistrationController,
} from "views/pages/registration/registration.controller";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	type ILiveUpdatesStore,
	LiveUpdatesStore,
} from "data/stores/live_updates/live_updates.store";
import {type IHeaderController, HeaderController} from "views/components/header/header.controller";
import {type IStatesStore, StatesStore} from "data/stores/states/states.store";
import {
	ChangePasswordController,
	type IChangePasswordController,
} from "views/components/update_password/update_password.controller";
import {
	DeleteAccountController,
	type IDeleteAccountController,
} from "views/components/delete_account/delete_account.controller";
import {type ILogoutController, LogoutController} from "views/components/logout/logout.controller";
import {
	type ILeagueManageController,
	LeagueManageController,
} from "views/pages/league_manage/league_manage.controller";
import {
	type ILeagueCardController,
	LeagueCardController,
} from "views/components/league_card/league_card.controller";
import {
	type ILeaguesSearchController,
	LeaguesSearchController,
} from "views/components/leagues_search/leagues_search.controller";
import {
	type IJoinLeaguesCardController,
	JoinLeaguesCardController,
} from "views/components/league_card/join_leagues_card.controller";
import {
	type IModalLeagueJoinController,
	ModalLeagueJoinController,
} from "views/components/modal_league_join/modal_league_join.controller";
import {
	type ILeagueLadderController,
	LeagueLadderController,
} from "views/pages/league_ladder/league_ladder.controller";
import {
	type IPredictorController,
	PredictorController,
} from "views/pages/predictor/predictor.controller";
import {type IPredictionsStore, PredictionsStore} from "data/stores/predictions/predictions.store";
import {
	type IPredictionsApiProvider,
	PredictionsApiProvider,
} from "data/providers/api/predictions.api.provider";
import {
	type IPredictionsListController,
	PredictionsListController,
} from "views/components/predictor/predictions_list/predictions_list.controller";
import {
	type IPredictionsListItemController,
	PredictionsListItemController,
} from "views/components/predictor/predictions_list_item/predictions_list_item.controller";
import {
	BottomActionsController,
	type IBottomActionsController,
} from "views/components/bottom_actions/bottom_actions.controller";
import {
	type IPredictionSavedModalController,
	PredictionSavedModalController,
} from "views/components/modal_prediction_saved/modal_prediction_saved.controller";
import {
	ClearPredictionModalController,
	type IClearPredictionModalController,
} from "views/components/modals/modal_clear_prediction/modal_clear_prediction.controller";
import {
	type IMakePredictionsModalController,
	MakePredictionsModalController,
} from "views/components/modals/modal_make_predictions/modal_make_predictions.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/components/live_score/live_score.controller";
import {
	type IPredictorSummaryBarController,
	PredictorSummaryBarController,
} from "views/components/predictor/predictor_summary_bar/predictor_summary_bar.controller";
import {
	type IMobileMenuController,
	MobileMenuController,
} from "views/components/mobile_menu/mobile_menu.controller";
import {type INavigationStore, NavigationStore} from "data/stores/navigation/navigation.store";
import {
	type IRankingsController,
	RankingsController,
} from "views/pages/rankings/rankings.controller";
import {type IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {type ITutorialStore, TutorialStore} from "data/stores/tutorial/tutorial.store";
import {
	type ITutorialController,
	TutorialController,
} from "views/components/tutorial/tutorial.controller";
import {
	type ITutorialStepController,
	TutorialStepController,
} from "views/components/tutorial/tutorial_step/tutorial_step.controller";
import {type IGeoBlockStore, GeoBlockStore} from "data/stores/geo_block/geo_block.store";
import {
	type IGeoBlockController,
	GeoBlockController,
} from "views/components/geo_block/geo_block.controller";
import {type IGeoBlockProvider, GeoBlockProvider} from "data/providers/api/geo_block.provider";
import {type IMonthsStore, MonthsStore} from "data/stores/months/months.store";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IPredictionsApiProvider>(Bindings.PredictionsApiProvider).to(PredictionsApiProvider);
	bind<IGeoBlockProvider>(Bindings.GeoBlockProvider).to(GeoBlockProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILiveUpdatesStore>(Bindings.LiveUpdatesStore).to(LiveUpdatesStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IStatesStore>(Bindings.StatesStore).to(StatesStore).inSingletonScope();
	bind<IPredictionsStore>(Bindings.PredictionsStore).to(PredictionsStore).inSingletonScope();
	bind<INavigationStore>(Bindings.NavigationStore).to(NavigationStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<ITutorialStore>(Bindings.TutorialStore).to(TutorialStore).inSingletonScope();
	bind<IGeoBlockStore>(Bindings.GeoBlockStore).to(GeoBlockStore).inSingletonScope();
	bind<IMonthsStore>(Bindings.MonthsStore).to(MonthsStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IRegistrationController>(Bindings.RegistrationController).to(RegistrationController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IChangePasswordController>(Bindings.ChangePasswordController).to(ChangePasswordController);
	bind<IDeleteAccountController>(Bindings.DeleteAccountController).to(DeleteAccountController);
	bind<ILogoutController>(Bindings.LogoutController).to(LogoutController);
	bind<ILeagueManageController>(Bindings.LeagueManageController).to(LeagueManageController);
	bind<ILeagueCardController>(Bindings.LeagueCardController).to(LeagueCardController);
	bind<ILeaguesSearchController>(Bindings.LeaguesSearchController).to(LeaguesSearchController);
	bind<IJoinLeaguesCardController>(Bindings.JoinLeaguesCardController).to(
		JoinLeaguesCardController
	);
	bind<IModalLeagueJoinController>(Bindings.ModalLeagueJoinController).to(
		ModalLeagueJoinController
	);
	bind<ILeagueLadderController>(Bindings.LeagueLadderController).to(LeagueLadderController);
	bind<IPredictorController>(Bindings.PredictorController).to(PredictorController);
	bind<IPredictionsListController>(Bindings.PredictionsListController).to(
		PredictionsListController
	);
	bind<IPredictionsListItemController>(Bindings.PredictionsListItemController).to(
		PredictionsListItemController
	);
	bind<IBottomActionsController>(Bindings.BottomActionsController).to(BottomActionsController);
	bind<IPredictionSavedModalController>(Bindings.PredictionSavedModalController).to(
		PredictionSavedModalController
	);
	bind<IClearPredictionModalController>(Bindings.ClearPredictionModalController).to(
		ClearPredictionModalController
	);
	bind<IMakePredictionsModalController>(Bindings.MakePredictionsModalController).to(
		MakePredictionsModalController
	);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<IPredictorSummaryBarController>(Bindings.PredictorSummaryBarController).to(
		PredictorSummaryBarController
	);
	bind<IMobileMenuController>(Bindings.MobileMenuController).to(MobileMenuController);
	bind<IRankingsController>(Bindings.RankingsController).to(RankingsController);
	bind<ITutorialController>(Bindings.TutorialController).to(TutorialController);
	bind<ITutorialStepController>(Bindings.TutorialStepController).to(TutorialStepController);
	bind<IGeoBlockController>(Bindings.GeoBlockController).to(GeoBlockController);
});
