import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IGameBar, IPrediction, IPredictionForSave} from "data/stores/predictions/predictions.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {GAME_SLUG} from "data/constants";

type TPredictionsResponse = IApiResponse<{predictions: IPrediction[]}>;

export interface IPredictionsApiProvider {
	fetchPredictions: (
		data: IGetPredictionsPayload
	) => Promise<AxiosResponse<TPredictionsResponse>>;
	savePredictions: (
		data: ISavePredictionsPayload
	) => Promise<AxiosResponse<TPredictionsResponse>>;
	clearPredictions: (
		data: IClearPredictionsPayload
	) => Promise<AxiosResponse<TPredictionsResponse>>;
	autoFillPredictions: (
		data: IAutoFillPredictionsPayload
	) => Promise<AxiosResponse<TPredictionsResponse>>;
	fetchGameBar: (data: IGameBarPayload) => Promise<AxiosResponse<TGameBarResponse>>;
}

interface IGetPredictionsPayload {
	gameWeekId: number;
}

interface IClearPredictionsPayload {
	gameWeekId: number;
}

interface ISavePredictionsPayload {
	predictions: IPredictionForSave[];
	gameWeekId: number;
}

interface IAutoFillPredictionsPayload {
	gameWeekId: number;
	predictions: IPredictionForSave[];
}

interface IGameBarPayload {
	gameWeekId?: number;
}

type TGameBarResponse = IApiResponse<IGameBar>;

@injectable()
export class PredictionsApiProvider implements IPredictionsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetchPredictions = ({gameWeekId}: IGetPredictionsPayload) =>
		this._http.get<TPredictionsResponse>(`${GAME_SLUG}/predictions/gameweek/${gameWeekId}`);
	savePredictions = ({predictions, gameWeekId}: ISavePredictionsPayload) =>
		this._http.post<TPredictionsResponse>(`${GAME_SLUG}/predictions/save`, {
			predictions,
			gameweekId: gameWeekId,
		});
	clearPredictions = ({gameWeekId}: IClearPredictionsPayload) =>
		this._http.post<TPredictionsResponse>(`${GAME_SLUG}/predictions/delete`, {
			gameweekId: gameWeekId,
		});
	autoFillPredictions = ({gameWeekId, predictions}: IAutoFillPredictionsPayload) =>
		this._http.post<TPredictionsResponse>(`${GAME_SLUG}/predictions/autofill`, {
			gameweekId: gameWeekId,
			predictions,
		});
	fetchGameBar = ({gameWeekId}: IGameBarPayload) =>
		this._http.get<TGameBarResponse>(`${GAME_SLUG}/ranking/user/summary-bar`, {
			roundId: gameWeekId,
		});
}
