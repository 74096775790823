import React from "react";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {type IHeaderController} from "views/components/header/header.controller";
import {useViewController} from "data/services/locator";
import styled from "@emotion/styled";
import {ReactComponent as Logo} from "assets/img/logo.svg";
import {Exist} from "views/components/exist/exist.component";
import {Link, NavLink} from "react-router-dom";
import {AccountCircleRounded, InfoRounded, ShareRounded} from "@mui/icons-material";
import {useLocation} from "react-router";
import {IconButton, Stack} from "@mui/material";
import {MobileMenu} from "views/components/mobile_menu/mobile_menu.component";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {PureButton} from "views/components/pure_button/pure_button.component";
import {useIsMobile} from "data/hooks";
import {ReactComponent as XIcon} from "assets/img/icons/xIconRounded.svg";
import {ReactComponent as FBIcon} from "assets/img/icons/fbIconRounded.svg";

const HeaderWrap = styled.header`
	background: #220e35;
	display: flex;
	flex-direction: column;
	&.mobile-open {
		position: fixed;
		height: 100vh;
		height: -webkit-fill-available;
		z-index: 10;
		width: 100%;
		overflow: auto;
	}
`;

const Row = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 44px;
	&.higher {
		height: 64px;
	}
	a,
	button {
		height: 44px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 6px;
	}
`;

const Links = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	a {
		padding: 18px 16px 16px;
		color: #c7c7cc;
		text-align: center;
		font-family: var(--fontFamilyBase);
		font-size: 14px;
		font-weight: 400;
		line-height: 140%;
		transition: all 0.2s ease-out;
		border-bottom: 2px solid transparent;
		&.active,
		&:hover {
			color: #ffdffb;
			border-bottom: 10px solid;
			border-image-source: linear-gradient(270deg, #de0180 2.03%, #9913b9 97.08%);
			border-image-slice: 1;
			border-bottom-width: 3px;
			border-top: 0;
		}
	}
`;

export const Header: React.FC = observer(() => {
	const location = useLocation();
	const {isMobile, isMobileShare} = useIsMobile();
	const {
		i18n,
		isAuth,
		isPrizeEnd,
		isMobileMenuOpen,
		showTutorial,
		toggleMenu,
		shareViaMobile,
		shareViaTW,
		shareViaFB,
	} = useViewController<IHeaderController>(Bindings.HeaderController, {location});
	return (
		<HeaderWrap className={isMobileMenuOpen ? "mobile-open" : ""}>
			<Row className="py-2.5 higher">
				<IconButton
					sx={{paddingLeft: 0}}
					className="button-icon text-[#fff] md:hidden"
					onClick={toggleMenu}>
					{isMobileMenuOpen ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
				</IconButton>
				<Link to="/">
					<Logo />
				</Link>
				<div className="flex justify-end items-center gap-4">
					<Exist when={isMobileShare}>
						<IconButton onClick={shareViaMobile}>
							<ShareRounded />
						</IconButton>
					</Exist>
					<Exist when={!isMobile}>
						<Stack direction="row">
							<IconButton onClick={shareViaFB}>
								<FBIcon />
							</IconButton>
							<IconButton onClick={shareViaTW}>
								<XIcon />
							</IconButton>
						</Stack>
					</Exist>

					<Exist when={!isAuth}>
						<Link to={"login"} className="text-right">
							<span className="hidden md:flex">
								{i18n.t("navigation.login.copy")}
							</span>
							<AccountCircleRounded />
						</Link>
					</Exist>
					<Exist when={isAuth}>
						<Link to={"my-account"} className="text-right">
							<span className="hidden md:flex">
								{i18n.t("navigation.my_account.copy")}
							</span>
							<AccountCircleRounded />
						</Link>
					</Exist>
				</div>
			</Row>
			<Exist when={isMobile && isMobileMenuOpen}>
				<MobileMenu />
			</Exist>
			<Row className="hidden md:flex">
				<Links className={"justify-between"}>
					<Stack direction="row">
						<Exist when={isAuth}>
							<NavLink to={"predictor"}>
								{i18n.t("navigation.my_predictions.copy")}
							</NavLink>

							<NavLink to={"leagues"}>{i18n.t("navigation.leagues.copy")}</NavLink>

							<NavLink to={"rankings"}>{i18n.t("navigation.rankings.copy")}</NavLink>
						</Exist>
						<NavLink to={"prizes"}>{i18n.t("navigation.prizes.copy")}</NavLink>
						<NavLink to={"help"} end={isPrizeEnd}>
							{i18n.t("navigation.help.copy")}
						</NavLink>
					</Stack>

					<Stack direction="row">
						<Link
							target={"_blank"}
							to={
								"https://sooka.my/en/watch/sponsored/details/malaysian-football-league/1498181"
							}>
							{i18n.t("navigation.sooka_highlight.copy", "MFL Highlights")}
						</Link>
						<Link target={"_blank"} to={"https://sooka.my/m/about-us/"}>
							{i18n.t("navigation.what_is_sooka.copy", "What is sooka?")}
						</Link>
						<Link target={"_blank"} to={"https://sooka.my/en/"}>
							{i18n.t("navigation.sooka_home.copy", "Go To sooka")}
						</Link>
					</Stack>
					<PureButton onClick={showTutorial}>
						{i18n.t("navigation.tutorial.copy", "Tutorial")}
						<InfoRounded />
					</PureButton>
				</Links>
			</Row>
		</HeaderWrap>
	);
});
