import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {
	ILeaguesApiProvider,
	IRankings,
	IRankingsPayload,
} from "data/providers/api/leagues.api.provider";
import {size} from "lodash";
import {Bindings} from "data/constants/bindings";

export interface IRankingsStore {
	get rankings(): IRankings["leaderboard"];

	fetchOverallRankings(params: IRankingsPayload): Promise<void>;
	fetchMoreOverallRankings(params: IRankingsPayload): Promise<void>;
	fetchRoundRankings(params: IRankingsPayload): Promise<void>;
	fetchMoreRoundRankings(params: IRankingsPayload): Promise<void>;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _rankings: IRankings["leaderboard"] = {
		rankings: [],
		user: null,
		nextPage: false,
	};
	private _perPage = 20;

	constructor(
		@inject(Bindings.LeaguesApiProvider) private _leaguesApiProvider: ILeaguesApiProvider
	) {
		makeAutoObservable(this);
	}

	get rankings() {
		return this._rankings;
	}

	@action
	async fetchOverallRankings(params: IRankingsPayload) {
		return this._leaguesApiProvider
			.fetchOverallRankings({limit: this._perPage, ...params})
			.then((response) =>
				runInAction(() => {
					this._rankings = response.data.success.leaderboard;
				})
			);
	}

	@action
	async fetchMoreOverallRankings(params: IRankingsPayload) {
		const {rankings} = this._rankings;
		const page = size(rankings) / this._perPage;

		return this._leaguesApiProvider
			.fetchOverallRankings({
				page: page,
				limit: this._perPage,
				...params,
			})
			.then((response) =>
				runInAction(() => {
					const {rankings, nextPage} = response.data.success.leaderboard;

					this._rankings.rankings.push(...rankings);
					this._rankings.nextPage = nextPage;
				})
			);
	}

	@action
	async fetchRoundRankings(params: IRankingsPayload) {
		return this._leaguesApiProvider
			.fetchWeeklyRoundRankings({limit: this._perPage, ...params})
			.then((response) =>
				runInAction(() => {
					this._rankings = response.data.success.leaderboard;
				})
			);
	}

	@action
	async fetchMoreRoundRankings(params: IRankingsPayload) {
		const {rankings} = this._rankings;
		const page = size(rankings) / this._perPage;

		return this._leaguesApiProvider
			.fetchWeeklyRoundRankings({
				page: page,
				limit: this._perPage,
				...params,
			})
			.then((response) =>
				runInAction(() => {
					const {rankings, nextPage} = response.data.success.leaderboard;

					this._rankings.rankings.push(...rankings);
					this._rankings.nextPage = nextPage;
				})
			);
	}
}
