import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, SyntheticEvent} from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {SelectChangeEvent} from "@mui/material";
import dayjs from "dayjs";

interface IMyAccountFormElement extends HTMLFormElement {
	displayName: HTMLInputElement;
	email: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;

	terms: HTMLInputElement;
	phone: HTMLInputElement;
	squad: HTMLSelectElement;
	birthday: HTMLInputElement;
	gender: HTMLSelectElement;
}

export interface IMyAccountController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get displayName(): string;
	get user(): IUser;
	get isUpdateDisabled(): boolean;
	get squads(): ISquad[];
	get selectedSquad(): string | number;
	get birthday(): string;
	get phone(): string;

	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleLogout: () => void;

	handleChangeSquad: (event: SelectChangeEvent<unknown>) => void;
	handleChangePhone: (value: string) => void;
	handleBirthChange: (event: ChangeEvent<HTMLInputElement>) => void;
	updateNotifications: (event: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";
	@observable private _isFormChanged = false;
	@observable private _selectedSquad: string | number = "";
	@observable private _birthday: string = "";
	@observable private _phone: string = "";

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get displayName() {
		return this._displayName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get phone() {
		return this._phone;
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);

		this._displayName = this._userStore.user!.displayName;
		this._selectedSquad = this._userStore.user!.teamSupported;
		this._birthday = this._userStore.user!.dob.toString();
		this._phone = this._userStore.user!.phone;
	}

	get squads() {
		return this._squadsStore.list;
	}

	get selectedSquad() {
		return this._selectedSquad;
	}

	get birthday() {
		return this._birthday;
	}

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {displayName, email, phone, squad, birthday} = event.currentTarget;

		const errorNoBirthdaySelected = this.i18n.t(
			"registration.birthday.error",
			"Please select DoB"
		);

		const errorNoPhoneSelected = this.i18n.t(
			"registration.phone.error",
			"Please enter phone number"
		);

		const dobValidator = () => {
			const maxDate = dayjs().subtract(18, "years").get("year");
			const minDate = dayjs().subtract(100, "years").get("year");
			const dob = Number(birthday.value);

			return dob && dob >= minDate && dob <= maxDate;
		};

		const validateList = [
			{field: email, error: "Please provide a valid email address", place: "email"},
			{field: displayName, error: "Please provide your display name", place: "displayName"},
			{field: phone, error: errorNoPhoneSelected, place: "phone"},
			{
				field: birthday,
				error: errorNoBirthdaySelected,
				place: "birthday",
				validator: dobValidator,
			},
		];

		const hasError = validateList.find(
			({field, error, place, validator}) =>
				!(validator || field.checkValidity).call(field) && this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			displayName: displayName.value,
			email: email.value,
			phone: phone.value,
			teamSupported: Number(squad.value),
			dob: Number(birthday.value),
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action updateNotifications = (event: ChangeEvent<HTMLInputElement>) => {
		const payload = {
			isNotificationsEnabled: event.target.checked,
		};
		this._requestState = RequestState.PENDING;
		this._userStore.updateNotifications(payload).then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.logout().then(this.onSuccess).catch(this.onError);
	};

	@action handleChangeSquad = (event: SelectChangeEvent<unknown>) => {
		this._selectedSquad = event.target.value as string;

		this.handleClearErrorOnChange();
	};

	@action handleChangePhone = (value: string) => {
		this._phone = value;

		this.handleClearErrorOnChange();
	};

	@action handleBirthChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length > 4) {
			return;
		}

		this._birthday =
			event.target.value === "" || isNaN(Number(event.target.value))
				? ""
				: event.target.value;
	};

	init() {
		Promise.all([this._squadsStore.fetchSquads()]).catch(this.onError);
	}
}
