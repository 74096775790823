import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";

export interface IGeoResponse {
	isAllowed: boolean;
}

export interface IGeoBlockProvider {
	geo_block: () => Promise<AxiosResponse<IApiResponse<IGeoResponse>>>;
}

@injectable()
export class GeoBlockProvider implements IGeoBlockProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	geo_block = () => this._http.get<IApiResponse<IGeoResponse>>(`en/geo_block`);
}
