import React, {Fragment, ReactNode} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IGeoBlockController} from "views/components/geo_block/geo_block.controller";
import {PagePreloader} from "views/components/preloader";
import {IS_NO_BLOCK} from "data/constants";

export const GeoBlock: React.FC<{children: ReactNode}> = observer(({children}) => {
	const {isNeedToRedirect, isAllowed} = useViewController<IGeoBlockController>(
		Bindings.GeoBlockController
	);
	if (IS_NO_BLOCK) {
		return <Fragment>{children}</Fragment>;
	}

	if (isNeedToRedirect) {
		window.location.href = "https://sooka.my/";
	}

	return isAllowed ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
