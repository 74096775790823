import {makeAutoObservable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {ViewController} from "data/types/structure";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IUserStore} from "data/stores/user/user.store";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

export interface IClearPredictionModalController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;

	closeHandler: () => void;
	submitHandler: () => void;
}

@injectable()
export class ClearPredictionModalController implements IClearPredictionModalController {
	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._modalsStore.modal === ModalType.CLEAR_PREDICTIONS;
	}

	closeHandler = () => {
		this._modalsStore.hideModal();
	};

	submitHandler = () => {
		this._modalsStore.hideModal();

		if (!this._userStore.isAuthorized) {
			this._predictionsStore.predictions = [];
			return;
		}

		const selectedGameWeek = this._predictionsStore.selectedGameWeek;

		if (!selectedGameWeek) {
			return;
		}

		this._predictionsStore.clear(selectedGameWeek.id).catch((err) => {
			runInAction(() => {
				const message = extractErrorMessage(err as AxiosError<IApiResponse>);
				this._modalsStore.showModal(ModalType.ERROR, {message});
			});
		});
	};
}
