import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {type ViewController} from "data/types/structure";
import {IUser, type IUserStore} from "data/stores/user/user.store";
import Swiper from "swiper";

export interface ITutorialController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get activeStep(): number;
	get user(): IUser | undefined;
	setIsOpen: (isOpen: boolean) => void;
	setActiveStep: (swiper: Swiper) => void;
	close: () => void;
	next: () => void;
	prev: () => void;
	restart: () => void;
}

@injectable()
export class TutorialController implements ITutorialController {
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._tutorialStore.isOpen;
	}

	get activeStep() {
		return this._tutorialStore.activeStep;
	}

	get user() {
		return this._userStore.user;
	}

	setIsOpen = (isOpen: boolean) => {
		this._tutorialStore.setIsOpen(isOpen);
	};

	setActiveStep = (swiper: Swiper) => {
		this._tutorialStore.setActiveStep(swiper.activeIndex + 1);
	};

	close = () => {
		this._tutorialStore.setIsOpen(false);
		if (!this._userStore.user?.isTutorialWatched) {
			void this._userStore.update({
				isTutorialWatched: true,
			});
		}
	};

	next = () => {
		this._tutorialStore.setActiveStep(this.activeStep + 1);
	};

	prev = () => {
		this._tutorialStore.setActiveStep(this.activeStep - 1);
	};

	restart = () => {
		this._tutorialStore.setActiveStep(0);
	};
}
