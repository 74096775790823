import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {LeaderboardSortOrder, ModalType, RequestState, SortOrder} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";
import {SyntheticEvent} from "react";
import type {ILeague, ILeagueLadder} from "data/providers/api/leagues.api.provider";

interface IProps {
	leagueId: number;
}

export type IRoundSelectOptions = number | "overall";

export interface ILeagueLadderController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;

	get rounds(): IRound[];

	get league(): ILeague | null;

	get selectedRound(): IRoundSelectOptions;

	get table(): ILeagueLadder["ladder"];

	get showUserRank(): boolean;

	get isOverall(): boolean;

	get orderBy(): LeaderboardSortOrder;

	get orderDirection(): SortOrder;
	get isLeaguePresenceRequestLoading(): boolean;
	get userTeamId(): number | undefined;

	changeRound: (event: SelectChangeEvent<string>) => void;
	loadMoreRanks: () => void;
	handleOrder: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

@injectable()
export class LeagueLadderController implements ILeagueLadderController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _leagueId: number | null = null;
	@observable private _viewedLeaderboardRound: IRoundSelectOptions = "overall";
	@observable private _roundChangeDisposer?: ReturnType<typeof reaction>;
	@observable private _changeLeagueDisposer?: ReturnType<typeof reaction>;
	@observable private _sortChangeDisposer?: ReturnType<typeof reaction>;
	@observable private _orderBy = LeaderboardSortOrder.Rank;
	@observable private _orderDirection = SortOrder.ASC;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,

		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	@computed
	get rounds() {
		const league = this._leaguesStore.getLeagueById(this._leagueId as number);
		const rounds = this._roundsStore.listForLadder;
		if (!league) {
			return rounds;
		}
		return rounds.filter((round) => {
			return round.id >= league.startId;
		});
	}

	get selectedRound() {
		return this._viewedLeaderboardRound;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get table() {
		const defaultTable = {
			rankings: [],
			user: null,
			nextPage: false,
		};

		if (!this._leagueId) {
			return defaultTable;
		}

		return this._leaguesStore.getLeagueLeaderboardByLeagueId(this._leagueId) || defaultTable;
	}

	get showUserRank(): boolean {
		return (
			Boolean(this.table.user) &&
			!this.table.rankings.find((rank) => rank.teamId === this.table.user?.teamId)
		);
	}

	get userTeamId(): number | undefined {
		return this.table.user?.teamId;
	}

	get isOverall() {
		return this._viewedLeaderboardRound === "overall";
	}

	get orderBy() {
		return this._orderBy;
	}

	get orderDirection() {
		return this._orderDirection;
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	private get roundNumber() {
		return this._viewedLeaderboardRound === "overall" ? null : this._viewedLeaderboardRound;
	}

	@action
	loadMoreRanks = async () => {
		if (!this._leagueId) return;

		this._requestState = RequestState.PENDING;

		if (this.roundNumber) {
			await this._leaguesStore
				.fetchMoreLeagueRoundLeaderboard({
					leagueId: this._leagueId,
					roundId: this.roundNumber,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess);
		} else {
			await this._leaguesStore
				.fetchMoreLeagueOverallLeaderboard({
					leagueId: this._leagueId,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				})
				.then(this.onSuccess);
		}
	};

	@action changeRound = (event: SelectChangeEvent<string>) => {
		this._viewedLeaderboardRound = event.target.value as IRoundSelectOptions;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action handleOrder = (e: SyntheticEvent<HTMLButtonElement>) => {
		const {value} = e.currentTarget;
		const isSelected = value === this._orderBy;

		if (isSelected) {
			this._orderDirection =
				this._orderDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		} else {
			this._orderBy = value as LeaderboardSortOrder;

			this._orderDirection = SortOrder.ASC;
		}

		console.log(value);
	};

	@action
	async init({leagueId}: IProps) {
		this._leagueId = leagueId;
		this._requestState = RequestState.PENDING;

		await Promise.all([
			this._squadsStore.fetchSquads(),
			this._roundsStore.fetchRounds(),
			this._leaguesStore.fetchLeague({
				leagueId: this._leagueId,
			}),
			this._leaguesStore.fetchLeagueOverallLadder({
				leagueId: this._leagueId,
				orderBy: this.orderBy,
				orderDir: this.orderDirection,
			}),
		])
			.then(this.onSuccess)
			.catch(this.onError);

		this._changeLeagueDisposer = reaction(
			() => this._leagueId,
			() => {
				if (!this._leagueId) return;

				void this._leaguesStore.fetchLeague({
					leagueId: this._leagueId,
				});

				this._orderBy = LeaderboardSortOrder.Rank;
				this._orderDirection = SortOrder.ASC;

				void this._leaguesStore.fetchLeagueOverallLadder({
					leagueId: this._leagueId,
					orderBy: this.orderBy,
					orderDir: this.orderDirection,
				});
			}
		);

		this._roundChangeDisposer = reaction(
			() => this._viewedLeaderboardRound,
			() => {
				void this.fetchLadder();
			}
		);

		this._sortChangeDisposer = reaction(
			() => [this.orderBy, this.orderDirection],
			() => {
				void this.fetchLadder();
			}
		);
	}

	@action
	fetchLadder = async () => {
		if (!this._leagueId) return;
		this._requestState = RequestState.PENDING;

		if (this.roundNumber) {
			await this._leaguesStore.fetchLeagueRoundLadder({
				leagueId: this._leagueId,
				roundId: this.roundNumber,
				orderBy: this.orderBy,
				orderDir: this.orderDirection,
			});
		} else {
			await this._leaguesStore.fetchLeagueOverallLadder({
				leagueId: this._leagueId,
				orderBy: this.orderBy,
				orderDir: this.orderDirection,
			});
		}
	};

	dispose() {
		this._roundChangeDisposer?.();
		this._changeLeagueDisposer?.();
		this._sortChangeDisposer?.();
	}
}
