import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IGeoBlockStore} from "data/stores/geo_block/geo_block.store";
import {RequestState} from "data/enums";
import {IS_NO_BLOCK} from "data/constants";

export interface IGeoBlockController extends ViewController {
	get isAllowed(): boolean;
	get isNeedToRedirect(): boolean;
}

@injectable()
export class GeoBlockController implements IGeoBlockController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(@inject(Bindings.GeoBlockStore) private _geoBlockStore: IGeoBlockStore) {
		makeAutoObservable(this);
	}

	get isAllowed() {
		return this._geoBlockStore.isAllowed;
	}

	get isNeedToRedirect() {
		return !this.isAllowed && this._requestState === RequestState.SUCCESS;
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	async init() {
		if (!IS_NO_BLOCK) {
			await this._geoBlockStore.fetchGeoBlock().then(this.onSuccess);
		}
	}
}
