import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {INavigationStore} from "data/stores/navigation/navigation.store";

export interface IMobileMenuController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isOpen(): boolean;
	get isAuth(): boolean;

	toggleMenu: () => void;
	closeMenu: () => void;
	logout: () => void;
}

@injectable()
export class MobileMenuController implements IMobileMenuController {
	@observable _isOpen: boolean = false;
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.NavigationStore) private _navigationStore: INavigationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._navigationStore.isOpen;
	}

	get isAuth() {
		return this._userStore.isAuthorized;
	}

	toggleMenu = () => {
		this._navigationStore.toggleMenu();
	};

	closeMenu = () => {
		this._navigationStore.closeMenu();
	};

	logout = () => {
		void this._userStore.logout();
		this.closeMenu();
	};
}
