import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType, RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ILogoutController extends ViewController {
	get i18n(): ILocalizationStore;

	get isRequestStatePending(): boolean;

	handleLogout: () => void;
}

@injectable()
export class LogoutController implements ILogoutController {
	@observable private _requestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isRequestStatePending() {
		return this._requestState === RequestState.PENDING;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;

		void this._userStore.logout().catch(this.onError);
	};
}
