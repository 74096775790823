import {RefObject, SyntheticEvent, useRef} from "react";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {isNumber} from "lodash";
import {MatchStatus, ScoreType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {IPrediction, type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {ViewController} from "data/types/structure";
import {type IUserStore} from "data/stores/user/user.store";
import type {IGame, IRoundsStore} from "data/stores/rounds/rounds.store";

// const MATCH_TIME_FIRST_HALF = 45;
// const MATCH_TIME_SECOND_HALF = 90;

interface IControllerProps {
	gameId: number;
}

export interface IPredictionsListItemController extends ViewController<IControllerProps> {
	i18n: ILocalizationStore;
	isOpenStats: boolean;

	get homeSquad(): ISquad | undefined;
	get awaySquad(): ISquad | undefined;
	get awayScore(): string;
	get homeScore(): string;
	get points(): number;
	get isGameScheduled(): boolean;
	get isGameLocked(): boolean;
	get isGameCompleted(): boolean;
	get isActionsDisabled(): boolean;
	get isShowQuickPicks(): boolean;
	get isShowStats(): boolean;
	get scoreType(): ScoreType;
	get game(): IGame | undefined;
	get pointsBreakdown(): IPrediction["pointsBreakdown"] | undefined;
	get awayScoreRef(): RefObject<HTMLInputElement>;
	get homeScoreRef(): RefObject<HTMLInputElement>;
	get cardRef(): RefObject<HTMLDivElement>;
	get activeTimeLabel(): string;

	inputChangeHandler: (e: SyntheticEvent<HTMLInputElement>) => void;
	quickPickHandler: (homeScore: number, awayScore: number, isMobile: boolean) => void;
	getQuickPickClassName: (homeScore: number, awayScore: number) => string;
	toggleHandler: () => void;
}

@injectable()
export class PredictionsListItemController implements IPredictionsListItemController {
	@observable private _gameId: number = 0;
	@observable public isOpenStats: boolean = false;
	private _awayScoreRef = useRef<HTMLInputElement>(null);
	private _homeScoreRef = useRef<HTMLInputElement>(null);
	private _cardRef = useRef<HTMLDivElement>(null);

	constructor(
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _gameWeeksStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get game() {
		return this._gameWeeksStore.getGameById(this._gameId);
	}

	get homeSquad() {
		return this._squadsStore.getSquadById(this.game?.homeId as number);
	}

	get awaySquad() {
		return this._squadsStore.getSquadById(this.game?.awayId as number);
	}

	get points() {
		return this.prediction?.points || 0;
	}

	get isGameScheduled() {
		return this.game?.status === MatchStatus.Scheduled;
	}

	get isGameLocked() {
		return (
			this.game?.status === MatchStatus.Active || this.game?.status === MatchStatus.Postponed
		);
	}

	get isGameCompleted() {
		return this.game?.status === MatchStatus.Completed;
	}

	get isActionsDisabled() {
		return !this.isGameScheduled;
	}

	get isShowQuickPicks() {
		return Boolean(this.game?.popularPredictions) && !this.isGameCompleted;
	}

	get isShowStats() {
		return this.isGameCompleted;
	}

	get scoreType() {
		if (
			this.game?.awayScore === this.prediction?.awayScore &&
			this.game?.homeScore === this.prediction?.homeScore
		) {
			return ScoreType.Green;
		}

		const points = this.prediction?.points;

		if (isNumber(points) && points > 0) {
			return ScoreType.Yellow;
		}

		// In case when points is calculated later than game is completed
		if (this.game?.status === MatchStatus.Completed && !isNumber(points)) {
			return ScoreType.Lock;
		}

		return ScoreType.Red;
	}

	get activeTimeLabel() {
		return "";
		// TODO: update when BE ready or remove
		// if (!this.game) return `${0}'`;
		//
		// const isHalfTime = this.game.period === GamePeriod.HalfTime;
		//
		// if (isHalfTime) {
		// 	return this.i18n.t("predictor.card.half_time", "HT");
		// }
		//
		// if (this.game.period === GamePeriod.FirstHalf && this.game.minute > MATCH_TIME_FIRST_HALF) {
		// 	return `${MATCH_TIME_FIRST_HALF}' + ${this.game.minute - MATCH_TIME_FIRST_HALF}'`;
		// }
		//
		// if (
		// 	this.game.period === GamePeriod.SecondHalf &&
		// 	this.game.minute > MATCH_TIME_SECOND_HALF
		// ) {
		// 	return `${MATCH_TIME_SECOND_HALF}' + ${this.game.minute - MATCH_TIME_SECOND_HALF}'`;
		// }
		//
		// return `${this.game.minute}'`;
	}

	private get prediction() {
		return this._predictionsStore.getPredictionByGameId(this._gameId);
	}

	get homeScore() {
		return this.prediction?.homeScore?.toString() || "";
	}

	get awayScore() {
		return this.prediction?.awayScore?.toString() || "";
	}

	get pointsBreakdown() {
		return this.prediction?.pointsBreakdown;
	}

	get awayScoreRef() {
		return this._awayScoreRef;
	}

	get homeScoreRef() {
		return this._homeScoreRef;
	}

	get cardRef() {
		return this._cardRef;
	}

	init({gameId}: IControllerProps) {
		this._gameId = gameId;
	}

	inputChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
		const field = e.currentTarget;
		const name = field.name as "homeScore" | "awayScore";
		const value = field.value;
		const opponentRef = name === "awayScore" ? this._homeScoreRef : this._awayScoreRef;

		if (!field.checkValidity()) {
			return;
		}

		if (value && !opponentRef?.current?.value) {
			opponentRef?.current?.focus();
		}

		this.updatePredictions(name, value);
	};

	private updatePredictions = (name: "homeScore" | "awayScore", value: string) => {
		this._predictionsStore.updatePredictions({
			homeScore: this.prediction?.homeScore,
			awayScore: this.prediction?.awayScore,
			[name]: value ? Number(value) : undefined,
			gameId: this._gameId,
			points: null,
			pointsBreakdown: null,
		});

		if (
			isNumber(this.prediction?.homeScore) &&
			isNumber(this.prediction?.awayScore) &&
			name === "awayScore"
		) {
			this.moveCursorToNextCard();
		}
	};

	quickPickHandler = (homeScore: number, awayScore: number, isMobile: boolean) => {
		this._predictionsStore.updatePredictions({
			homeScore,
			awayScore,
			gameId: this._gameId,
			points: null,
			pointsBreakdown: null,
		});

		if (!isMobile) {
			this.moveCursorToNextCard();
		}
	};

	getQuickPickClassName = (homeScore: number, awayScore: number) => {
		if (!this.awayScore || !this.homeScore) {
			return "";
		}

		if (homeScore === Number(this.homeScore) && awayScore === Number(this.awayScore)) {
			return "selected";
		}

		return "";
	};

	toggleHandler = () => {
		this.isOpenStats = !this.isOpenStats;
	};

	// move cursor to the next match
	private moveCursorToNextCard = () => {
		const nextCardRef = this._cardRef?.current?.nextElementSibling;

		if (nextCardRef) {
			nextCardRef.querySelector("input")?.focus();
		}
	};
}
