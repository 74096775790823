import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {LeaguePrivacy, LeagueStatus, LeagueType, SortOrder} from "data/enums";
import {GAME_SLUG} from "data/constants";

export interface ILeague {
	id: number;
	name: string;
	startId: number;
	leagueManager: ILeagueManager | null;
	code: string | null;
	privacy: LeaguePrivacy;
	status: LeagueStatus;
	class: LeagueType;
	numTeams: number;
	isJoined: boolean;
	overallRank?: number | null;
	prevOverallRank?: number | null;
	squadId?: number;
}

export interface ICreateLeaguePayload {
	name: string;
	startId: number; // Start round (must exist and be with the SCHEDULED status)
	privacy: LeaguePrivacy;
}

export interface ILeagueIdPayload {
	leagueId: number;
}

interface IPaginationPayload {
	page?: number;
	limit?: number;
}

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & ILeagueIdPayload;

export interface ILeaguesForJoinPayload extends IPaginationPayload {
	search?: string;
}

export interface ILeagueInvitesPayload extends ILeagueIdPayload {
	invites: {email: string}[];
}

export interface IRemoveUserFromLeaguePayload extends ILeagueIdPayload {
	userId: number;
}

export interface ILeagueCodePayload {
	code: string;
}

export type ILeagueUsersPayload = ILeagueIdPayload & IPaginationPayload;

export interface ILeaguesPayload extends IPaginationPayload {
	privacy?: LeaguePrivacy;
	search?: string;
}

export interface ILeagueManager {
	userId: number | null;
	displayName: string;
}

export interface ILeagueUser extends ILeagueManager {
	userId: number;
}

interface ILeagueRank {
	teamId: number;
	teamName: string;
	leagueId: number;
	rank: number | null;
	points: number | null;
	gameweekPoints: number | null;
	avgPoints: string | null;
	prevRank: number | null;
}

export interface ILeagueRankingsPayload extends ILeagueIdPayload {
	page?: number;
	limit?: number;
	roundId?: number | null;
	orderBy?: string;
	orderDir: SortOrder;
}

export interface IRankingsPayload {
	page?: number;
	limit?: number;
	roundId?: number;
	monthId?: number;
	orderBy?: string;
	orderDir: SortOrder;
}

export interface ILeagueLadder {
	ladder: {
		rankings: ILeagueRank[];
		user: ILeagueRank | null;
		nextPage: boolean;
	};
}

export interface IRankings {
	leaderboard: {
		rankings: ILeagueRank[];
		user: ILeagueRank | null;
		nextPage: boolean;
	};
}

type ILeagueResponse = IApiResponse<{league: ILeague}>;
type ILeaguesListResponse = IApiResponse<{leagues: ILeague[]; nextPage: boolean}>;
type ILeagueUserListResponse = IApiResponse<{users: ILeagueUser[]; nextPage: boolean}>;
type ILeagueLadderResponse = IApiResponse<ILeagueLadder>;
type IRankingsResponse = IApiResponse<IRankings>;
type ILeagueToJoinResponse = IApiResponse<ILeague>;

export interface ILeaguesApiProvider {
	createLeague: (params: ICreateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	updateLeague: (params: IUpdateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeaguesForJoin: (
		params: ILeaguesForJoinPayload
	) => Promise<AxiosResponse<ILeaguesListResponse>>;
	leaveLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	inviteUsersToLeague: (params: ILeagueInvitesPayload) => Promise<AxiosResponse<void>>;
	removeUserFromLeague: (params: IRemoveUserFromLeaguePayload) => Promise<AxiosResponse<void>>;
	joinToLeague: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeagueUsers: (
		params: ILeagueUsersPayload
	) => Promise<AxiosResponse<ILeagueUserListResponse>>;
	fetchMyLeagues: (params: ILeaguesPayload) => Promise<AxiosResponse<ILeaguesListResponse>>;
	fetchLeagueByCode: (
		params: ILeagueCodePayload
	) => Promise<AxiosResponse<ILeagueToJoinResponse>>;

	fetchLeagueOverallLadder: (
		params: ILeagueRankingsPayload
	) => Promise<AxiosResponse<ILeagueLadderResponse>>;

	fetchLeagueRoundLadder: (
		params: ILeagueRankingsPayload
	) => Promise<AxiosResponse<ILeagueLadderResponse>>;

	fetchOverallRankings: (params: IRankingsPayload) => Promise<AxiosResponse<IRankingsResponse>>;

	fetchWeeklyRoundRankings: (
		params: IRankingsPayload
	) => Promise<AxiosResponse<IRankingsResponse>>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	createLeague = (params: ICreateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`${GAME_SLUG}/league`, params);

	updateLeague = ({leagueId, ...params}: IUpdateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`${GAME_SLUG}/league/${leagueId}`, params);

	fetchLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<ILeagueResponse>(`${GAME_SLUG}/league/${leagueId}`);

	fetchLeaguesForJoin = (params: ILeaguesForJoinPayload) =>
		this._http.get<ILeaguesListResponse>(`${GAME_SLUG}/league/show-for-join`, params);

	leaveLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/leave`);

	inviteUsersToLeague = ({leagueId, ...params}: ILeagueInvitesPayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/invite`, params);

	removeUserFromLeague = ({leagueId, userId}: IRemoveUserFromLeaguePayload) =>
		this._http.post<void>(`${GAME_SLUG}/league/${leagueId}/user/${userId}`);

	joinToLeague = ({code}: ILeagueCodePayload) =>
		this._http.post<ILeagueResponse>(`${GAME_SLUG}/league/${code}/join`);

	fetchLeagueUsers = ({leagueId, ...params}: ILeagueUsersPayload) =>
		this._http.get<ILeagueUserListResponse>(
			`${GAME_SLUG}/league/${leagueId}/league-users`,
			params
		);

	fetchMyLeagues = (params: ILeaguesPayload) =>
		this._http.get<ILeaguesListResponse>(`${GAME_SLUG}/leagues`, params);

	fetchLeagueByCode = ({code}: ILeagueCodePayload) =>
		this._http.get<ILeagueToJoinResponse>(`${GAME_SLUG}/league/show-by-code/${code}`);

	fetchLeagueOverallLadder = (params: ILeagueRankingsPayload) =>
		this._http.get<ILeagueLadderResponse>(`${GAME_SLUG}/ranking/overall_ladder`, params);

	fetchLeagueRoundLadder = (params: ILeagueRankingsPayload) =>
		this._http.get<ILeagueLadderResponse>(`${GAME_SLUG}/ranking/round_ladder`, params);

	fetchOverallRankings = (params: IRankingsPayload) =>
		this._http.get<IRankingsResponse>(`${GAME_SLUG}/rankings/overall_leaderboard`, params);
	fetchWeeklyRoundRankings = (params: IRankingsPayload) =>
		this._http.get<IRankingsResponse>(`${GAME_SLUG}/rankings/round_leaderboard`, params);
}
