import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague} from "data/providers/api/leagues.api.provider";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {find} from "lodash";
import {useNavigate} from "react-router-dom";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {ModalType} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IProps {
	code?: string;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IModalLeagueJoinController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get error(): string | null;
	get league(): ILeague | null;
	get isOpen(): boolean;

	close: () => void;
	join: () => void;
}

@injectable()
export class ModalLeagueJoinController implements IModalLeagueJoinController {
	@observable _navigate!: ReturnType<typeof useNavigate>;
	@observable _code: string | null = null;
	@observable _error: string | null = null;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		return this._error;
	}

	get league() {
		return this._leaguesStore.leagueToJoin;
	}

	get isOpen() {
		return this._modalsStore.modal === ModalType.LEAGUE_JOIN;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	@action
	join = () => {
		if (!this._code) {
			return;
		}

		this._leaguesStore
			.joinToLeague({code: this._code})
			.then(() =>
				this.redirectToMyLeagueByCode({
					code: this._code as string,
					replace: true,
				})
			)
			.catch(this.onError);
	};
	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._error = extractErrorMessage(error);
	};

	private redirectToMyLeagueByCode = ({
		code,
		replace = false,
	}: {
		code: string;
		replace?: boolean;
	}) => {
		const {leagues} = this._leaguesStore.myLeagues;
		const id = find(leagues, {code})?.id;

		if (id) {
			this._leaguesStore.clearLeaguesForJoin();
			this._navigate(`/league/${id}/`, {replace});
		}
	};

	@action init({code, navigate}: IProps) {
		this._navigate = navigate;
		if (code) {
			this._code = code;
			void this._leaguesStore.fetchLeagueByCode({code});
		}
	}
}
