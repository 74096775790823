import React from "react";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {ITutorialStepController} from "views/components/tutorial/tutorial_step/tutorial_step.controller";
import {TutorialDesc, TutorialTitle} from "views/components/tutorial/common";

interface IProps {
	index: number;
	img: string;
	isFirst?: boolean;
	isLast?: boolean;
	rulesLink?: string;
	stepDescription?: string;
}

export const TutorialStep: React.FC<IProps> = ({
	index: step,
	isFirst,
	isLast,
	img,
	rulesLink,
	stepDescription,
}) => {
	const {i18n} = useViewController<ITutorialStepController>(Bindings.TutorialStepController);

	return (
		<div className="w-full max-w-[380px] m-auto">
			<img src={img} loading="lazy" alt="" className="mb-6 mx-auto w-[50px]" />

			<TutorialTitle
				className="mb-2"
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: i18n.t(`tutorial.step_${step}.header`, "title"),
				}}
			/>
			<TutorialDesc
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: i18n.t(`tutorial.step_${step}.body`, "description"),
				}}
			/>
		</div>
	);
};
