import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";

const Login = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const Registration = lazy(
	retryFailLoad(() => import("views/pages/registration/registration.page"))
);
const ForgotPassword = lazy(
	retryFailLoad(() => import("views/pages/forgot_password/forgot_password.page"))
);
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueInvite = lazy(
	retryFailLoad(() => import("views/pages/league_invite/league_invite.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));
const LeagueManage = lazy(
	retryFailLoad(() => import("views/pages/league_manage/league_manage.page"))
);
const LeagueLadder = lazy(
	retryFailLoad(() => import("views/pages/league_ladder/league_ladder.page"))
);

const Predictor = lazy(retryFailLoad(() => import("views/pages/predictor/predictor.page")));

const Landing = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));

const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const Prizes = lazy(retryFailLoad(() => import("views/pages/prizes/prizes.page")));

export const RootRoutes = (
	<Fragment>
		<Route element={<NotAuthOnlyRoute />}>
			<Route index element={<Landing />} />
			<Route path="/login" element={<Login />} />
			<Route path="/registration" element={<Registration />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/predictor" element={<Predictor />} />
			<Route path="/my-account" element={<MyAccount />} />
			<Route path="/leagues" element={<MyLeagues />} />
			<Route path="/leagues/join" element={<JoinLeagues />}>
				<Route index path=":code" element={<JoinLeagues />} />
			</Route>
			<Route path="/leagues/create" element={<CreateLeague />} />
			<Route path="/league/:leagueId" element={<League />}>
				<Route path="invite" element={<LeagueInvite />} />
				<Route path="settings" element={<LeagueSettings />} />
				<Route path="about" element={<LeagueAbout />} />
				<Route path="manage" element={<LeagueManage />} />
				<Route path="ladder" element={<LeagueLadder />} />
			</Route>
			<Route path="/rankings" element={<Rankings />} />
		</Route>
		<Route path="prizes" element={<Prizes />} />
		<Route path="help/*" element={<Help />} />

		<Route path="*" element={<NotFound />} />
	</Fragment>
);

export default RootRoutes;
