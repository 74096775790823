import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeagues, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ISquadsStore} from "data/stores/squads/squads.store";

export interface IMyLeaguesController extends ViewController {
	readonly i18n: ILocalizationStore;

	get leagues(): ILeagues;
	get isLoading(): boolean;
	get hasJoinLeagues(): boolean;
	get leaguesForJoin(): ILeagues;

	loadMoreJoinLeagues: () => void;
	loadMoreMyLeagues: () => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable _requestState = RequestState.PENDING;

	get leagues() {
		return this._leaguesStore.myLeagues;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get leaguesForJoin() {
		return this._leaguesStore.leaguesForJoin;
	}

	get hasJoinLeagues() {
		return this.leaguesForJoin.leagues.length > 0;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this._leaguesStore.fetchMoreMyLeagues().then(this.onSuccess).catch(this.onError);
	};

	loadMoreJoinLeagues = () => {
		this._leaguesStore.fetchMoreLeaguesForJoin().then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action init() {
		Promise.all([this._leaguesStore.fetchMyLeagues(), this._squadsStore.fetchSquads()])
			.then(this.onSuccess)
			.catch(this.onError);
	}

	dispose() {
		this._leaguesStore.clearLeaguesForJoin();
	}
}
