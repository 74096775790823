import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import {type IModalsStore} from "data/stores/modals/modals.store";

export interface IPredictionSavedModalController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;

	closeHandler: () => void;
}

@injectable()
export class PredictionSavedModalController implements IPredictionSavedModalController {
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._modalsStore.modal === ModalType.PREDICTIONS_SAVED;
	}

	closeHandler = () => {
		this._modalsStore.hideModal();
	};
}
