import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";
import type {IModalConfirmController} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	CloseBlock,
	ModalContent,
} from "views/components/modals/modal_content/modal_content.components";

const Icon = styled.div`
	width: 52px;
	margin-bottom: 15px;
	svg {
		width: 100%;
		height: auto;
		fill: #ff0033;
	}
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<Button className="text-[#161125]" sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<Icon>
					<ErrorIcon />
				</Icon>
				<p className="text-[24px] md:text-[28px] font-bold ">
					{i18n.t(modalContent?.title ?? "modal.confirm.title", "Confirm")}
				</p>
				<Exist when={modalContent?.message}>
					<Typography
						className="w-full max-w-[360px] text-[14px] md:text-[16px]"
						variant="body3">
						{modalContent?.message}
					</Typography>
				</Exist>
				<Stack
					direction={{xs: "column", md: "row"}}
					spacing={3}
					alignItems="center"
					sx={{width: "100%", maxWidth: "360px"}}>
					<Button fullWidth variant="contained" color="secondary" onClick={close}>
						{i18n.t("modal.confirm.cancel_button", "Cancel")}
					</Button>
					<Exist when={modalContent?.callback}>
						<Button
							fullWidth
							variant="outlined"
							color="error"
							onClick={modalContent?.callback}>
							{i18n.t("modal.confirm.confirm_button", "Confirm")}
						</Button>
					</Exist>
				</Stack>
			</ModalContent>
		</Modal>
	);
});
