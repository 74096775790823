import {computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {get, isNumber} from "lodash";
import {Bindings} from "data/constants/bindings";
import {MatchStatus, ModalType, RequestState} from "data/enums";
import {type IPredictionsApiProvider} from "data/providers/api/predictions.api.provider";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IUserStore} from "data/stores/user/user.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

export interface IPrediction {
	gameId: number;
	homeScore?: number;
	awayScore?: number;
	points: number | null;
	pointsBreakdown: {
		correctScore: number;
		correctGoalsHome: number;
		correctGoalsAway: number;
		correctOutcome: number;
		correctGoalsDiff: number;
	} | null;
}

export interface IPredictionForSave {
	gameId: number;
	homeScore: number;
	awayScore: number;
}

export interface IGameBar {
	gameweekId: number | null;
	gameweekPoints: number | null;
	gameweekRank: number | null;
	points: number | null;
}

export interface IPredictionsStore {
	get predictions(): IPrediction[];
	set predictions(prediction: IPrediction[]);
	get isShowAutoFillButton(): boolean;
	get isShowClearButton(): boolean;
	get isDisabledSaveButton(): boolean;
	get isShowSaveButton(): boolean;
	get predictionsForSave(): IPredictionForSave[];
	get isLoadingFetchPredictions(): boolean;
	get isLoadingSavePredictions(): boolean;
	get isLoadingAutoFillPredictions(): boolean;
	get isClearAutoFillPredictions(): boolean;
	get clearPredictionRequestState(): RequestState;
	get autoFillPredictionRequestState(): RequestState;
	get isDisabledAnyActions(): boolean;
	get gameBar(): IGameBar;
	get selectedGameWeek(): IRound | undefined;
	get nextGameWeekId(): number | undefined;
	get hasChanges(): boolean;

	fetchPredictions: (gameWeekId: number) => Promise<void>;
	autoFill: (gameWeekId: number) => Promise<void>;
	clear: (gameWeekId: number) => Promise<void>;
	save: (gameWeekId: number) => Promise<void>;
	updatePredictions: (prediction: IPrediction) => void;
	getPredictionByGameId(gameId: number): IPrediction | undefined;
	fetchGameBar(gameWeekId?: number): Promise<void>;
	setSelectedRound: (id: number) => void;
}

@injectable()
export class PredictionsStore implements IPredictionsStore {
	@observable private _predictions: IPrediction[] = [];
	@observable private _hasChanges: boolean = false;
	@observable private _gamebar: IGameBar = {
		gameweekId: null,
		gameweekPoints: null,
		gameweekRank: null,
		points: null,
	};
	@observable private _fetchPredictionRequestState: RequestState = RequestState.IDLE;
	@observable private _savePredictionRequestState: RequestState = RequestState.IDLE;
	@observable private _clearPredictionRequestState: RequestState = RequestState.IDLE;
	@observable private _autoFillPredictionRequestState: RequestState = RequestState.IDLE;
	@observable private _gameBarRequestState: RequestState = RequestState.IDLE;
	@observable private _selectedGameWeekId: number = 0;

	constructor(
		@inject(Bindings.RoundsStore) private _gameWeeksStore: IRoundsStore,
		@inject(Bindings.PredictionsApiProvider)
		private _predictionsApiProvider: IPredictionsApiProvider,
		@inject(Bindings.LocalizationStore) public _i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get hasChanges() {
		return this._hasChanges;
	}

	get selectedGameWeek() {
		return (
			this._gameWeeksStore.getRoundById(this._selectedGameWeekId) ||
			this._gameWeeksStore.currentRound
		);
	}

	get selectedGameWeekGames() {
		return get(this.selectedGameWeek, "games", []);
	}

	get nextGameWeekId() {
		const gwId = this.selectedGameWeek?.id;

		if (gwId) {
			return this._gameWeeksStore.getRoundById(gwId + 1)?.id;
		}
	}

	private get selectedGameWeekScheduledGames() {
		return this.selectedGameWeekGames.filter(({status}) => status === MatchStatus.Scheduled);
	}

	get predictions() {
		return this._predictions;
	}

	set predictions(predictions: IPrediction[]) {
		this._predictions = predictions;
	}

	get isShowAutoFillButton() {
		const scheduledGames = this.selectedGameWeekScheduledGames;

		if (!scheduledGames?.length) {
			return false;
		}

		return !scheduledGames.every(({id}) => this.predictions.find(({gameId}) => gameId === id));
	}

	get isShowClearButton() {
		const scheduledGames = this.selectedGameWeekScheduledGames;

		if (!scheduledGames?.length) {
			return false;
		}

		return scheduledGames.some(({id}) =>
			this.predictions.find(
				({gameId, awayScore, homeScore}) =>
					gameId === id && (isNumber(awayScore) || isNumber(homeScore))
			)
		);
	}

	get isDisabledSaveButton() {
		if (this.isDisabledAnyActions) {
			return true;
		}

		const scheduledGames = this.selectedGameWeekScheduledGames;

		if (!scheduledGames.length) {
			return true;
		}

		const isPredictionsValid = scheduledGames.every(({id}) =>
			this.predictions.find(
				({gameId, awayScore, homeScore}) =>
					gameId === id && isNumber(awayScore) && isNumber(homeScore)
			)
		);

		if (!isPredictionsValid) {
			return true;
		}

		return !this._hasChanges;
	}

	get isShowSaveButton() {
		return !this.isDisabledSaveButton && !this.isShowAutoFillButton;
	}

	@computed
	get predictionsForSave(): IPredictionForSave[] {
		const games = this.selectedGameWeekScheduledGames;
		return this.predictions
			.filter(({gameId}) => games.find(({id}) => gameId === id))
			.filter(({awayScore, homeScore}) => isNumber(awayScore) && isNumber(homeScore))
			.map((prediction) => ({
				gameId: prediction.gameId,
				awayScore: prediction.awayScore as number,
				homeScore: prediction.homeScore as number,
			}));
	}

	get isLoadingFetchPredictions() {
		return this._fetchPredictionRequestState === RequestState.PENDING;
	}

	get isLoadingSavePredictions() {
		return this._savePredictionRequestState === RequestState.PENDING;
	}

	get isLoadingAutoFillPredictions() {
		return this._autoFillPredictionRequestState === RequestState.PENDING;
	}

	get isClearAutoFillPredictions() {
		return this._clearPredictionRequestState === RequestState.PENDING;
	}

	get clearPredictionRequestState() {
		return this._clearPredictionRequestState;
	}

	get autoFillPredictionRequestState() {
		return this._autoFillPredictionRequestState;
	}

	@computed
	get isDisabledAnyActions() {
		return (
			this.isLoadingSavePredictions ||
			this.isLoadingFetchPredictions ||
			this.isLoadingAutoFillPredictions ||
			this.isClearAutoFillPredictions
		);
	}

	get gameBar() {
		return this._gamebar;
	}

	setSelectedRound = (id: number) => {
		this._selectedGameWeekId = id;
	};

	async fetchPredictions(gameWeekId: number) {
		try {
			this._fetchPredictionRequestState = RequestState.PENDING;

			const response = await this._predictionsApiProvider.fetchPredictions({
				gameWeekId,
			});

			runInAction(() => {
				this._predictions = response.data.success.predictions;
				this._fetchPredictionRequestState = RequestState.SUCCESS;
				this._hasChanges = false;
			});
		} catch (err) {
			runInAction(() => {
				this._fetchPredictionRequestState = RequestState.ERROR;
			});

			throw err;
		}
	}

	async autoFill(gameWeekId: number) {
		try {
			this._autoFillPredictionRequestState = RequestState.PENDING;

			const response = await this._predictionsApiProvider.autoFillPredictions({
				predictions: this.predictionsForSave,
				gameWeekId,
			});

			runInAction(() => {
				this._predictions = response.data.success.predictions;
				this._autoFillPredictionRequestState = RequestState.SUCCESS;
				this._hasChanges = true;
			});
		} catch (err) {
			runInAction(() => {
				this._autoFillPredictionRequestState = RequestState.ERROR;
			});

			throw err;
		} finally {
			runInAction(() => {
				this._autoFillPredictionRequestState = RequestState.IDLE;
			});
		}
	}

	async clear(gameWeekId: number) {
		try {
			this._clearPredictionRequestState = RequestState.PENDING;

			const response = await this._predictionsApiProvider.clearPredictions({
				gameWeekId,
			});

			runInAction(() => {
				this._predictions = response.data.success.predictions;
				this._clearPredictionRequestState = RequestState.SUCCESS;
			});
		} catch (err) {
			const message = extractErrorMessage(err as AxiosError<IApiResponse>);

			runInAction(() => {
				this._modalsStore.showModal(ModalType.ERROR, {message});
				this._clearPredictionRequestState = RequestState.ERROR;
			});

			throw err;
		} finally {
			runInAction(() => {
				this._clearPredictionRequestState = RequestState.IDLE;
			});
		}
	}

	async save(gameWeekId: number) {
		try {
			this._savePredictionRequestState = RequestState.PENDING;

			const response = await this._predictionsApiProvider.savePredictions({
				predictions: this.predictionsForSave,
				gameWeekId,
			});

			runInAction(() => {
				this._predictions = response.data.success.predictions;
				this._hasChanges = false;
				this._savePredictionRequestState = RequestState.SUCCESS;
			});
		} catch (err) {
			runInAction(() => {
				this._savePredictionRequestState = RequestState.ERROR;
			});

			throw err;
		}
	}

	updatePredictions = (prediction: IPrediction) => {
		this._hasChanges = true;

		this._predictions = this._predictions.filter(({gameId}) => gameId !== prediction.gameId);

		this._predictions.push(prediction);
	};

	getPredictionByGameId = (gameId: number) => {
		return this._predictions.find((prediction) => prediction.gameId === gameId);
	};

	async fetchGameBar(gameWeekId?: number): Promise<void> {
		try {
			this._gameBarRequestState = RequestState.PENDING;

			await Promise.resolve(); // mock
			const response = await this._predictionsApiProvider.fetchGameBar({
				gameWeekId,
			});

			runInAction(() => {
				this._gamebar = response.data.success;
				this._gameBarRequestState = RequestState.SUCCESS;
			});
		} catch (err) {
			runInAction(() => {
				this._gameBarRequestState = RequestState.ERROR;
			});

			throw err;
		}
	}
}
