import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ModalType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {ViewController} from "data/types/structure";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IUserStore} from "data/stores/user/user.store";
import {extractErrorMessage} from "data/utils";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

export interface IMakePredictionsModalController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get nextGameWeekId(): number | undefined;

	closeHandler: () => void;
	submitHandler: () => void;
}

@injectable()
export class MakePredictionsModalController implements IMakePredictionsModalController {
	constructor(
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._modalsStore.modal === ModalType.MAKE_PREDICTIONS;
	}

	get nextGameWeekId() {
		return this._predictionsStore.nextGameWeekId;
	}

	closeHandler = () => {
		this._modalsStore.hideModal();
	};

	submitHandler = () => {
		this._modalsStore.hideModal();

		const gameWeekId = this.nextGameWeekId;

		if (gameWeekId) {
			this._predictionsStore.setSelectedRound(gameWeekId);

			if (this._userStore.isAuthorized) {
				this._predictionsStore.fetchPredictions(gameWeekId).catch(this.onError);
				this._predictionsStore.fetchGameBar(gameWeekId).catch(this.onError);
			}
		}
	};

	private onError = (err: AxiosError<IApiResponse>) => {
		const message = extractErrorMessage(err);
		this._modalsStore.showModal(ModalType.ERROR, {
			message,
		});
	};
}
