import {retryFailLoad} from "data/utils";
import React, {Fragment, lazy} from "react";
import {Route} from "react-router-dom";

const Landing = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));

export const SecretGateRoutes = (
	<Fragment>
		<Route path="/" element={<Landing />} />
		<Route path="*" element={<Landing />} />
	</Fragment>
);

export default SecretGateRoutes;
